import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { CMSDataService } from 'src/app/shared/services/cmsdata.service';

@Component({
	selector: 'app-sponsor',
	templateUrl: './sponsor.component.html',
	styleUrls: ['./sponsor.component.scss']
})
export class SponsorComponent implements OnInit {
	
	logoKey: string = 'default';

	private _breakpoint: string = '';
	@Input()
	public get breakpoint(): string {
		return this._breakpoint;
	}
	public set breakpoint(val: string) {
		this._breakpoint = val;
		this.breakLarge = (val === 'lg');
	}
	@HostBinding('class.break-large')
	breakLarge: boolean = false;

	private _theme: string = '';
	@Input()
	public get theme(): string {
		return this._theme;
	}
	public set theme(val: string) {
		this._theme = val;
		this.themeDark = (val === 'dark');
		this.themeLight = (val === 'light');
		if (val === 'dark' || val === 'light') this.logoKey = val;
	}
	@HostBinding('class.theme-dark')
	themeDark: boolean = false;
	@HostBinding('class.theme-light')
	themeLight: boolean = false;
	
	constructor(public cmsDataService:CMSDataService) { }
	
	ngOnInit(): void {
	}
	
}

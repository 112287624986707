<div class="flex-initial select-none inline-block text-3xl lg:text-4xl font-headings uppercase leading-8">
	<a [attr.aria-hidden]="menuActive ? 'true' : 'false'" tabindex="{{ menuActive ? '-1' : '0' }}" 
	routerLink="{{ authService.isLoggedIn ? '/lessons' : '/welcome' }}" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
		<img class="mr-3 -mt-1 h-10 w-10 lg:h-12 lg:w-12" src="../../../../assets/img/seal_moh.png" alt="">
		<span class="text-white mr-2">Path to Honor</span>
		<br class="md:hidden landscape:hidden">
		<span id="topictext" class="text-blue-light hidden md:inline-block">{{ topicText }}</span>
	</a>
</div>

<div class="flex-auto justify-end inline-flex items-center">

	<div class="pr-16 md:pr-20 leading-5">
		<span *ngIf="authService.isLoggedIn && authService.userAcct as user">
			<span class="text-gray-light mr-2">Signed in as</span>
			<span class="block md:inline landscape:inline">{{ user.displayName ? user.displayName.slice(0, 24) + (user.displayName.length > 24 ? '&hellip;' : '') : 'Google User' }}</span>
		</span>
		<span *ngIf="!authService.isLoggedIn"><a [attr.aria-hidden]="menuActive ? 'true' : 'false'" tabindex="{{ menuActive ? '-1' : '0' }}" class="underline underline-offset-4 decoration-gray-light" (click)="authService.GoogleAuth()">Sign in <br class="md:hidden landscape:hidden">with Google</a></span>
	</div>

	<div class="z-20" [ngClass]="menuActive ? 'fixed' : 'absolute'">
		<button id="nav_toggle" [attr.aria-expanded]="menuActive ? 'true' : 'false'" aria-haspopup="true" aria-controls="nav_main" aria-label="Menu" class="h-10 flex justify-end" [ngClass]="(menuActive || !authService.isLoggedIn) ? 'w-10' : 'w-40 md:w-60 lg:w-80'" (click)="menuToggle($event)">
			<svg aria-hidden="true" viewBox="0 0 12 10" id="menutoggle" class="hamburger w-10 h-10" >
				<path d="M10,2 L2,2" class="upper" style="fill: none; stroke: white; stroke-linecap: round;"/>
				<path d="M2,5 L10,5" class="middle" style="fill: none; stroke: white; stroke-linecap: round;"/>
				<path d="M10,8 L2,8" class="lower" style="fill: none; stroke: white; stroke-linecap: round;"/>
			</svg>
		</button>
	</div>
</div>



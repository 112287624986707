<app-video-player *ngIf="loadVideo && !(activePrompt || activeCertification)" [attr.aria-hidden]="menuActive || activePoll || activePrompt || activeCertification || !lessonService.titleScreenDismissed ? 'true' : 'false'" tabIndexValue="{{ menuActive || activePoll || activePrompt || activeCertification || !lessonService.titleScreenDismissed ? '-1' : '0' }}"></app-video-player>
<app-poll *ngIf="hasPoll && activePoll" [attr.aria-hidden]="menuActive ? 'true' : 'false'" tabIndexValue="{{ menuActive ? '-1' : '0' }}" [@pollScreenAnimation] (@pollScreenAnimation.start)="scrollToTop()"></app-poll>
<app-prompt *ngIf="hasCertificate && activePrompt" [attr.aria-hidden]="menuActive ? 'true' : 'false'" tabIndexValue="{{ menuActive ? '-1' : '0' }}" [@certificationScreenAnimation]></app-prompt>
<app-certification *ngIf="hasCertificate && activeCertification" [attr.aria-hidden]="menuActive ? 'true' : 'false'" tabIndexValue="{{ menuActive ? '-1' : '0' }}" [@certificationScreenAnimation]></app-certification>
<app-title-screen *ngIf="!lessonService.titleScreenDismissed" [attr.aria-hidden]="menuActive ? 'true' : 'false'" [@titleAnimation] (@titleAnimation.done)="loadVideoPlayer($event)">
	
	<div class="block md:inline-block">
		
		<div class="flex flex-col-reverse md:flex-row justify-center space-y-6 space-y-reverse md:space-y-0 md:space-x-6 mt-6">
			<app-button *ngIf="lessonService.currentLesson?.completed && authService.isLoggedIn && authService.userAcct" tabIndexValue="{{ menuActive ? '-1' : '0' }}" class="shy smwide" [icon]="icons.LIST" label="View My Certificates" navigateTo="/certificates"></app-button>
			<app-button *ngIf="lessonService.currentLesson?.completed && !authService.isLoggedIn && !authService.userAcct" tabIndexValue="{{ menuActive ? '-1' : '0' }}" class="shy smwide" [icon]="icons.LIST" label="Back To {{ lessonService.currentTopic }} Lessons" navigateTo="/lessons/{{ lessonService.topicSlug }}"></app-button>
			<app-button tabIndexValue="{{ menuActive ? '-1' : '0' }}" class="smwide" [ngClass]="vimeoPlayerReady ? '' : 'loading'" [icon]="vimeoPlayerReady ? icons.ARROW : icons.LOADING" 
			[label]="lessonService.currentLesson?.isIntroduction && !hasPoll ? 'Play Video' : ( ( lessonService.currentLesson?.completed ? 'Review' : 'Begin' ) + ' Lesson')" 
			[command]="'continue'" (onClick)="dismissTitleScreen()"
			[disabled]="!vimeoPlayerReady"></app-button>
		</div>
		
	</div>
	
	
</app-title-screen>

import packageJson from '../../package.json';

export const environment = {
  firebase: {
    projectId: 'cp-cmoh-cv-dev',
    appId: '1:715845840676:web:10f0d1302fc31a753a4db1',
    storageBucket: 'cp-cmoh-cv-dev.appspot.com',
    locationId: 'us-central',
    apiKey: 'AIzaSyAh2uhUX5vqKtLj_at83OZCWC7LC2mjTzo',
    authDomain: 'cp-cmoh-cv-dev.firebaseapp.com',
    messagingSenderId: '715845840676',
    measurementId: 'G-XW9VY50C4N',
    appCheck: {
      recaptchaV3SiteKey: '6LeUm0ImAAAAAGBcuSvsjfKgUUo7LHRlGgl0QNbw'
    }
  },
  appTitlePrefix: 'DEV - ',
  appVersion: packageJson.version + '-dev',
  production: false,
  cmsBucket: 'cp-cmoh-cv-dev-cms'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.

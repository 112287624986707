<section [@blockAnimation]>
	<header *ngIf="true" class="mt-8 mb-8 md:mb-12">
		<div class="mb-5 relative">
			<div class="aspect-[2.35/1]"><img [src]="course?.photoURL || '' | getDownloadURL" alt="" class="w-full rounded-3xl object-cover"></div>
		</div>
		<div class="flex flex-row items-center mb-3 md:pt-2">
			<h1 class="flex-grow !text-gray-light text-6xl md:text-7xl !pb-0 !mb-0"><span class="text-blue-dark">{{ course?.topic }}</span><!--<span class="hidden md:inline"> Lessons</span>--></h1>
			<div class="pl-5 min-w-[10.75rem] text-base md:text-2xl">
				<app-progress-bar [lessonsTotal]="lessonsTotal || 1" [lessonsCompleted]="lessonsCompleted" [courseProgress]="courseProgress"></app-progress-bar>
			</div>
		</div>
        <p class="text-2xl !mb-6">{{course?.description}}</p>
	</header>

	<div *ngFor="let lesson of course?.lessons" class="lesson w-full flex items-start pt-12 pb-8 border-t border-black/30
		flex-col
		md:flex-row
		">
		<div class="mb-5 relative">
			<div class="md:w-80 aspect-[2.35/1]"><img [src]="lesson?.photoURL || '' | getDownloadURL" alt="" alt=""
			class="w-full rounded-3xl md:rounded-xl object-cover"></div>

			<div *ngIf="lesson.completed" class="flex flex-row text-base md:text-sm text-offwhite uppercase font-semibold text-center
			absolute bottom-0 left-0 w-full">
				<div class="bg-gradient-to-r from-black/60 to-black/90 flex-grow rounded-bl-3xl md:rounded-bl-xl flex justify-end"><img class="spinastar w-4 aspect-square object-contain" src="./assets/img/star.png" alt="" /></div>
				<div class="bg-black/90 flex items-center px-2 pt-1.5 pb-1">Completed</div>
				<div class="bg-gradient-to-r from-black/90 to-black/60 flex-grow rounded-br-3xl md:rounded-br-xl flex"><img class="spinastarev w-4 aspect-square object-contain" src="./assets/img/star.png" alt="" /></div>
			</div>
		</div>
		<div class="w-full md:pl-10">
			<div>
				<p class="font-bold text-3xl leading-7">{{ lesson.title }}</p>
				<p *ngIf="lesson.subtitle" class="lsubt text-3xl leading-7">{{ lesson.subtitle }}</p>
				<p class="ldesc text-2xl">{{ lesson.description }}</p>
			</div>
			<app-button tabIndexValue="{{ menuActive ? '-1' : '0' }}" element="anchor" class="small smwide" [icon]="icons.ARROW" 
			ariaLabelOverride="{{ (lesson.completed ? 'Review Completed' : 'Begin') + ' Lesson: ' + lesson.title }}"
			label="{{ lesson.completed ? 'Review' : 'Begin' }}&nbsp;Lesson" [navigateTo]="generateLessonPath(lesson)"></app-button>
		</div>
	</div>

	<footer *ngIf="true" class="mt-16 md:mt-40 pt-12 border-t border-black/10 text-center">
		<app-button tabIndexValue="{{ menuActive ? '-1' : '0' }}" class="shy page smwide" element="anchor" [icon]="icons.LIST" label="View all Core Values" navigateTo="/lessons"></app-button>
	</footer>
</section>

import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class HeaderService {
	
	private _menuActive: BehaviorSubject<boolean>;

	//public defaultHeaderTopic: string = 'Core Values';
	public defaultHeaderTopic: string = '';
	private _topic: BehaviorSubject<string>;
	
	constructor() {
		this._topic = new BehaviorSubject<string>(this.defaultHeaderTopic);
		this._menuActive = new BehaviorSubject<boolean>(false);
	}
	
	public menuActive(): Observable<boolean> { return this._menuActive.asObservable(); }

	public getTopic(): Observable<string> { return this._topic.asObservable(); }

	public setTopic(value?: string) {
		const setValue: string = value || this.defaultHeaderTopic;
		this._topic.next(setValue);
	}

	public menuOpen(): void {
		this._menuActive.next(true);
	}
	public menuClose(): void {
		this._menuActive.next(false);
	}
	public menuToggle(): void {
		this._menuActive.next(!this._menuActive.value);
	}
}

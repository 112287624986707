import { Component, OnInit } from '@angular/core';
import { CMSDataService } from 'src/app/shared/services/cmsdata.service';
import { LessonService } from 'src/app/shared/services/lesson.service';

@Component({
	selector: 'app-title-screen',
	templateUrl: './title-screen.component.html',
	styleUrls: ['./title-screen.component.scss']
})
export class TitleScreenComponent implements OnInit {

	constructor(
		public lessonService: LessonService,
		public cmsDataService: CMSDataService,
	) {	}

	ngOnInit(): void {}

}

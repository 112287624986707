<div id="video-player" class="flex-auto relative">
	<div #player_container class="video-container bg-transparent absolute m-auto left-0 right-0 top-0 bottom-0 max-w-full max-h-full aspect-video"></div>
	<div #player_error_container id="video-player-errors" class="invisible absolute m-auto left-0 right-0 top-0 bottom-0 max-w-full max-h-full aspect-video
	flex items-center justify-center text-center bg-blue-dark">
	<div>
		<p class="pb-3 text-5xl font-bold">An Error Occurred</p>
		<p #player_error_message class="text-xl pb-8">{{ playerError }}</p>
		<app-button [label]="'Return to Home Page'" [navigateTo]="'/'"></app-button>
	</div>
</div>
</div>
<div id="video-controls" class="flex-initial bg-gray-dark px-6 py-6 md:px-8 md:py-6" [ngClass]="controlsEnabled ? '' : 'disabled'">
	<div class="flex flex-row space-x-6">
		<button tabindex="{{ tabIndexValue }}" [attr.aria-label]="isPlaying ? 'Pause' : 'Play'" id="play-pause" [disabled]="controlsEnabled ? null : 'disabled'" class="opacity-80 hover:opacity-100 scale-100 hover:scale-105 transition-all" (click)="clickPlayPause($event)"><img class="h-8 w-8" src="assets/icons/{{ isPlaying ? icons.PAUSE : icons.PLAY }}.svg"></button>
		
		<!--<progress [attr.aria-hidden]="controlsEnabled ? 'false' : 'true'" tabindex="{{ tabIndexValue }}" min="0" max="{{ videoDuration }}" value="{{ videoTime }}"></progress>-->

		<div id="video-progress" class="flex flex-1 items-center">
			<div class="flex-1 h-2 bg-white/20 rounded-lg relative">
				<div class="h-2 bg-blue-light rounded-lg w-0" [attr.style]="'width:' + videoProgress + '%'"></div>
				<div #cuepoint_indicators class="h-2 bg-transparent w-full absolute top-0 left-0"></div>
			</div>
		</div>
		<div id="video-timer" class="leading-8 font-sans">{{ videoTime | minuteSeconds }}<span class="hidden md:inline"> / {{ videoDuration | minuteSeconds }}</span></div>
		<button tabindex="{{ tabIndexValue }}" *ngIf="hasTextTrack" aria-label="Toggle Captions" [attr.aria-pressed]="ccEnabled ? 'true' : 'false'" id="cc" [disabled]="(hasTextTrack && controlsEnabled) ? null : 'disabled'" class="opacity-80 hover:opacity-100 scale-100 hover:scale-105 transition-all" (click)="toggleCC($event)"><img class="h-8 w-8" src="assets/icons/{{ ccEnabled ? icons.CC_DISABLE : icons.CC_ENABLE }}.svg"></button>
	</div>
</div>

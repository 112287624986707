import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { routes } from 'src/app/app-routing.module';
import { environment } from 'src/environments/environment';
import { DOCUMENT } from '@angular/common';
import { AuthService } from '../../../shared/services/auth.service';
import { CMSDataService } from 'src/app/shared/services/cmsdata.service';
import { HeaderService } from 'src/app/shared/services/header.service';
import { UserdataService } from 'src/app/shared/services/userdata.service';

@Component({
	selector: 'app-nav-main',
	templateUrl: './nav-main.component.html',
	styleUrls: ['./nav-main.component.scss']
})
export class NavMainComponent implements OnInit {
	
	@HostListener('document:keydown', ['$event']) onKeyDown(e: any) {
		if(e.key === 'Escape') {
			if (this.menuActive) {
				this.headerService.menuClose();
				document.getElementById('nav_toggle')?.focus();
			}
		}
	}

	currentRoutes = routes;
	
	menuActive: boolean = false;
	
	constructor(
		@Inject(DOCUMENT) document: Document, 
		public authService: AuthService, 
		public cmsDataService: CMSDataService, 
		public headerService: HeaderService,
		public userdataService: UserdataService
	) { }
	
	ngOnInit(): void {
		this.headerService.menuActive().subscribe( value => this.menuActive = value );
	}
	
	get currentApplicationVersion(): string {
		return environment.appVersion;
	}
	
}

import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Icons } from 'src/app/shared/enums/icons';
//import { ButtonCommand } from './button-command';

@Component({
	selector: 'app-button',
	templateUrl: './button.component.html',
	styleUrls: ['./button.component.scss']
})
export class ButtonComponent implements OnInit {
	@Input() label?: string;
	@Input() tabIndexValue: string = '0';
	@Input() ariaLabelOverride: string | null = null;
	@Input() icon?: Icons;
	@Input() command?: string;
	@Input() disabled?: boolean;
	@Input() navigateTo?: string;
	@Input() element?: string = 'button';
	@Output() onClick = new EventEmitter();

	icons = Icons;

	constructor() { }

	ngOnInit(): void {
	}

	emitEvent(event: Object): void {
		this.onClick.emit({ event, command: this.command });
	}
}

import { trigger, style, animate, transition, stagger, query } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { CMSDataService } from 'src/app/shared/services/cmsdata.service';
import { UserdataService } from "../../../shared/services/userdata.service";

@Component({
	selector: 'app-courses',
	templateUrl: './courses.component.html',
	styleUrls: ['./courses.component.scss'],
	animations: [
		trigger( 'blockAnimation', [
			transition('void => *', [
				query(':enter',	[
					style({ opacity: 0, transform: 'translateY(25px)' }),
					stagger(150, [
						animate('0.6s ease-out',
							style({ opacity: 1, transform: 'translateY(0)' })
						)
					]),
				]),
			]),
		]),
	],
})
export class CoursesComponent implements OnInit {

	constructor(
		public cmsDataService:CMSDataService,
		private userdataService: UserdataService
	) {
		this.userdataService.lessonRouteGuard()
	}

	ngOnInit(): void {
	}

}

<!-- Show user data when logged in -->
<div *ngIf="authService.isLoggedIn && authService.userAcct as user">
    <h2 class="text-5xl uppercase">Hello, {{(user.displayName) ? user.displayName : 'User'}}</h2>
    <p>Email:</p>
    <p><span class="font-bold text-sm">{{user.email}}</span></p>
    <p>User ID:</p>
    <p><span class="font-bold text-sm break-words">{{user.uid}}</span></p>
</div>
<div *ngIf="!authService.isLoggedIn">
    <h2 class="text-5xl uppercase">Hello, <strong>Guest</strong></h2>
</div>

<app-button
	[ngClass]="class"
	[element]="element"
	[icon]="icon"
	[label]="label"
	[command]="download ? 'download' : 'open'"
	(onClick)="generatePDF($event)"
	[disabled]="disabled"
	tabIndexValue="{{ tabIndexValue }}"
></app-button>


<app-sponsor theme="light"></app-sponsor>

<section class="max-w-3xl">
	<h1>{{ cmsDataService.configuration.signIn.title }}</h1>
	<p class="description" [innerHTML]="cmsDataService.configuration.signIn.description | filterKeyPhrases"></p>
	<div class="text-xl text-white uppercase space-y-10">
		<app-signin tabIndexValue="{{ menuActive ? '-1' : '0' }}"></app-signin>
	</div>
</section>



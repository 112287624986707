import { Component, Input, OnInit, inject } from '@angular/core';
import { Icons } from 'src/app/shared/enums/icons';
import { CMSDataService } from 'src/app/shared/services/cmsdata.service';
import { PageComponent } from '../page/page.component';
import { UserdataService } from "../../../shared/services/userdata.service";
import { LoggerService } from 'src/app/shared/services/logger.service';
import { UserLessonData } from 'src/app/shared/interfaces/userlessondata';

@Component({
	selector: 'app-certificates',
	templateUrl: './certificates.component.html',
	styleUrls: ['../page/page.component.scss', './certificates.component.scss'],
})
export class CertificatesComponent extends PageComponent implements OnInit {
	logger: LoggerService = inject(LoggerService);

	icons = Icons;
	completedLessonsForDisplay: Array<any> = [];
	displayName: string;

	constructor(
		public cmsDataService: CMSDataService,
		private userdataService: UserdataService,
	) {
		super();
		this.userdataService.certificatesRouteGuard();
		this.displayName = this.userdataService.getUserData().displayName ?? '';
	}

	override ngOnInit(): void {
		super.ngOnInit();
		this.subscriptions.push(
			this.cmsDataService.cmsDataEvent.subscribe(event => {
				if ('updateCompletedLessons' === event) {
					this.updateCompletedLessons()
				}
			})
		);
	}

	override ngOnDestroy(): void {
		super.ngOnDestroy();
	}


	updateCompletedLessons(): void {
		this.completedLessonsForDisplay = [];
		this.cmsDataService.configuration.courses.forEach(course => {
			let lc: Array<object> = [];
			course.lessons.forEach(lesson => {
				if(lesson.completed) {
					lc.push({
						id: lesson.cmsKey,
						slug: lesson.slug,
						title: lesson.title,
						subtitle: lesson.subtitle,
						prompt: lesson.finalQuestion,
						response: this.userdataService.userLessonData[lesson.id].prompt
					})
				}
			});
			if (lc.length > 0) {
				this.completedLessonsForDisplay.push({
					id: course.cmsKey,
					slug: course.slug,
					title: course.topic,
					lessons: lc
				})
			}
		})
		// this.logger.log('completedLessonsForDisplay', this.completedLessonsForDisplay)
	}
}



import { Component, Input, OnInit, inject } from '@angular/core';
import { Icons } from 'src/app/shared/enums/icons';
import { trigger, style, animate, transition, AnimationEvent } from '@angular/animations';
import { ActivatedRoute } from '@angular/router';
import { LessonService } from 'src/app/shared/services/lesson.service';
import { Observable, Subscription } from 'rxjs';
import { ViewportScroller } from '@angular/common';
import { UserdataService } from "../../shared/services/userdata.service";
import { HeaderService } from 'src/app/shared/services/header.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { ComponentCanDeactivate } from 'src/app/shared/guards/lesson-nav.guard';
import { UserData } from 'src/app/shared/interfaces/userdata';
import { LoggerService } from 'src/app/shared/services/logger.service';

@Component({
	selector: 'app-lesson',
	templateUrl: './lesson.component.html',
	styleUrls: ['./lesson.component.scss'],
	animations: [
		trigger(
			'titleAnimation',
			[
				transition(
					':enter',
					[
						style({ opacity: 0 }),
						animate('1s linear',
							style({ opacity: 1 }))
					]
				),
				transition(
					':leave',
					[
						style({ opacity: 1 }),
						animate('.5s linear',
							style({ opacity: 0 }))
					]
				)
			]
		),
		trigger(
			'pollScreenAnimation',
			[
				transition(
					':enter',
					[
						style({ opacity: 0 }),
						animate('.5s linear',
							style({ opacity: 1 }))
					]
				),
				transition(
					':leave',
					[
						style({ opacity: 1 }),
						animate('.5s linear',
							style({ opacity: 0 }))
					]
				)
			]
		),
		trigger(
			'certificationScreenAnimation',
			[
				transition(
					':enter',
					[
						style({ opacity: 0 }),
						animate('.5s linear',
							style({ opacity: 1 }))
					]
				),
				transition(
					':leave',
					[
						style({ opacity: 1 }),
						animate('.5s linear',
							style({ opacity: 0 }))
					]
				)
			]
		),
	  ]
})
export class LessonComponent implements OnInit, ComponentCanDeactivate {
	logger: LoggerService = inject(LoggerService);

	@Input() beginButtonLabel: string = 'Begin Lesson';

	loadVideo: boolean = false;
	hasPoll: boolean = false;
	activePoll: boolean = false;
	activePrompt: boolean = false;
	hasCertificate: boolean = false;
	activeCertification: boolean = false;
	vimeoPlayerReady: boolean = false;

	menuActive: boolean = false;

	subscriptions: Subscription[] = [];

	icons = Icons;

	constructor(
		private route: ActivatedRoute,
		public authService: AuthService,
		public lessonService: LessonService,
		private viewport: ViewportScroller,
		private userdataService: UserdataService,
		public headerService: HeaderService,
	) { }

	// @HostListener also guard against browser refresh, close, etc.
	// @HostListener('window:beforeunload')
	canDeactivate(): Observable<boolean> | boolean {
		// return true to navigate without confirmation
		// return false to show confirmation dialog before navigating away
		let canLeave: boolean = true;
		if (this.lessonService.lessonPlaying) {
			canLeave = false;
			if (this.lessonService.currentLesson?.isIntroduction) {
				// the userData property on userdataService is not updated / current to user state
				//this.logger.warn(this.userdataService.userData.introComplete);
				const userData: UserData = this.userdataService.getUserData();
				canLeave = userData.introComplete ?? false;
			} else {
				canLeave = this.lessonService.currentLesson?.completed ?? false;
			}
		}
		this.logger.log('Can Navigate away from Lesson without warning:', canLeave);
		return canLeave;
	}

	ngOnInit(): void {
		const topicSlug: string = this.route.snapshot.paramMap.get('topic') || this.route.snapshot.url[0].path;
		const lessonSlug: string = this.route.snapshot.paramMap.get('lesson') || '';

		if (topicSlug==='introduction') {
			this.userdataService.introRouteGuard()
		} else {
			this.userdataService.lessonRouteGuard()
		}

		this.lessonService.loadLessonFromRoute( topicSlug, lessonSlug );

		this.hasPoll = undefined !== this.lessonService.currentLesson?.polls;
		this.hasCertificate = !(this.lessonService.currentLesson?.finalQuestion?.trim().length == 0 || undefined === this.lessonService.currentLesson?.finalQuestion);
		//this.beginButtonLabel = this.lessonService.currentLesson?.isIntroduction && !this.hasPoll ? 'Play Video' : ( ( this.lessonService.currentLesson?.completed ? 'Review' : 'Begin' ) + ' Lesson');


		this.subscriptions.push(
			this.lessonService.isPollActive().subscribe( value => this.activePoll = value ),
			this.lessonService.isPromptActive().subscribe( value => this.activePrompt = value ),
			this.lessonService.isCertificationActive().subscribe( value => this.activeCertification = value ),
			this.lessonService.isVimeoPlayerReady().subscribe( value => this.vimeoPlayerReady = value ),
			this.headerService.menuActive().subscribe( value => this.menuActive = value ),
		);

	}

	ngOnDestroy(): void {
		this.lessonService.resetLesson();
		this.reset();
		this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
	}

	loadVideoPlayer(event: AnimationEvent) {
		if (event.fromState === 'void' && event.phaseName === 'done') this.loadVideo = true;
	}
	dismissTitleScreen() {
		this.lessonService.titleScreenDismissed = true;
		this.lessonService.lessonPlaying = true;
		this.lessonService.logLessonAnalytics('started');
		this.lessonService.playVideo();
	}

	reset(): void {
		this.loadVideo = false;
		this.hasPoll = false;
		this.hasCertificate = false;
	}

	scrollToTop(): void {
		this.viewport.scrollToPosition([0, 0]);
	}
}

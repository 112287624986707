import { HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireAnalyticsModule, ScreenTrackingService, UserTrackingService } from '@angular/fire/compat/analytics';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
//import { AngularFireDatabaseModule } from '@angular/fire/compat/database';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { AngularFireStorageModule, BUCKET } from '@angular/fire/compat/storage';
import { initializeAppCheck, ReCaptchaV3Provider } from 'firebase/app-check';
import { getApp } from 'firebase/app';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CertificationComponent } from './components/lesson/certification/certification.component';
import { LessonComponent } from './components/lesson/lesson.component';
import { ChoiceComponent } from './components/lesson/poll/choice/choice.component';
import { PollComponent } from './components/lesson/poll/poll.component';
import { PromptComponent } from './components/lesson/prompt/prompt.component';
import { TitleScreenComponent } from './components/lesson/title-screen/title-screen.component';
import { VideoPlayerComponent } from './components/lesson/video-player/video-player.component';
import { AboutComponent } from './components/pages/about/about.component';
import { CertificatesComponent } from './components/pages/certificates/certificates.component';
import { CourseComponent } from './components/pages/courses/course/course.component';
import { ProgressBarComponent } from './components/pages/courses/course/progress-bar/progress-bar.component';
import { CoursesComponent } from './components/pages/courses/courses.component';
import { LessonlistComponent } from './components/pages/courses/lessonlist/lessonlist.component';
import { PageComponent } from './components/pages/page/page.component';
import { WelcomeComponent } from './components/pages/welcome/welcome.component';
import { ButtonComponent } from './components/ui/button/button.component';
import { NavMainComponent } from './components/ui/nav-main/nav-main.component';
import { SiteHeaderComponent } from './components/ui/site-header/site-header.component';
import { GetCertificateComponent } from './components/util/get-certificate/get-certificate.component';
import { SigninComponent } from './components/util/signin/signin.component';
import { UserInfoComponent } from './components/util/user-info/user-info.component';
import { FilterKeyPhrasesPipe } from './shared/pipes/filterkeyphrases.pipe';
import { MinuteSecondsPipe } from './shared/pipes/minuteseconds.pipe';
import { AuthService } from "./shared/services/auth.service";
import { CMSDataService } from './shared/services/cmsdata.service';
import { HeaderService } from './shared/services/header.service';
import { SponsorComponent } from './components/ui/sponsor/sponsor.component';


export function initializeApp(cmsDataService: CMSDataService): () => Promise<any> {
	return () => cmsDataService.loadConfigurationFromCMS();
}


@NgModule({
	declarations: [
		AppComponent,
		ButtonComponent,
		SigninComponent,
		UserInfoComponent,
		GetCertificateComponent,
		SiteHeaderComponent,
		LessonComponent,
		PollComponent,
		FilterKeyPhrasesPipe,
		MinuteSecondsPipe,
		NavMainComponent,
		AboutComponent,
		VideoPlayerComponent,
		TitleScreenComponent,
		WelcomeComponent,
		CoursesComponent,
		PageComponent,
		CertificationComponent,
		CertificatesComponent,
		CourseComponent,
		LessonlistComponent,
		ProgressBarComponent,
		ChoiceComponent,
		PromptComponent,
  SponsorComponent,
	],
	imports: [
		BrowserModule,
		BrowserAnimationsModule,
		AppRoutingModule,
		AngularFireModule.initializeApp(environment.firebase),
		AngularFireAuthModule,
		AngularFirestoreModule,
		AngularFireStorageModule,
		//AngularFireDatabaseModule,
		AngularFireAnalyticsModule,
		FormsModule,
		HttpClientModule,
	],
	providers: [
		{
			provide: APP_INITIALIZER,
			useFactory: initializeApp,
			deps: [CMSDataService],
			multi: true,
		},
		{
			provide: BUCKET,
			useValue: environment.cmsBucket,
		},
		ScreenTrackingService,
		UserTrackingService,
		AuthService,
		HeaderService,
	],
	bootstrap: [AppComponent]
})
export class AppModule {
	// https://stackoverflow.com/questions/71912179/how-to-make-firebase-app-check-work-when-using-angularfire
	
	constructor() {
		this.initializeAppCheck();
	}

	private initializeAppCheck() {
		const firebaseApp = getApp();
		//(self as any).FIREBASE_APPCHECK_DEBUG_TOKEN = true; // Generate App Check Debug Token in Console
		initializeAppCheck(firebaseApp, {
			provider: new ReCaptchaV3Provider(environment.firebase.appCheck.recaptchaV3SiteKey),
			isTokenAutoRefreshEnabled: true
		});
	}
}

<nav id="nav_main" [attr.aria-hidden]="menuActive ? 'false' : 'true'" class="pt-6 md:pt-8 lg:pt-12 px-6 md:px-8 mt-16 overflow-y-auto max-h-[calc(100vh_-_90px)]">
	<ul>
		<li><a [tabIndex]="menuActive ? '0' : '-1'" routerLink="{{ authService.isLoggedIn ? '/lessons' : '/welcome' }}" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Home</a></li>

		<li>
			<a [tabIndex]="menuActive ? '0' : '-1'" (click)="userdataService.continueToApp()" routerLink="/introduction" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Introduction</a>
			<div class="text-xl font-normal mt-2">{{ cmsDataService.configuration.introduction.title | titlecase }}</div>
		</li>
	</ul>

	<ul class="border-t border-black/20 mt-6 pt-6 md:mt-8 md:pt-8 lg:mt-12 lg:pt-12">
		<li *ngIf="authService.isLoggedIn"><a [tabIndex]="menuActive ? '0' : '-1'" routerLink="/certificates" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">My Certificates</a></li>

		<li *ngIf="authService.isLoggedIn && authService.userAcct as user">
			<a [tabIndex]="menuActive ? '0' : '-1'" (click)="authService.SignOut()">Sign Out</a>
			<div class="text-xl font-normal mt-2">Signed in as <strong>{{ user.displayName ? user.displayName.slice(0, 24) + (user.displayName.length > 24 ? '&hellip;' : '') : 'Google User' }}</strong><br>
			<span class="overflow-hidden text-ellipsis w-full inline-block">{{ user.email }}</span></div>
		</li>
		<li *ngIf="!authService.isLoggedIn">
			<a [tabIndex]="menuActive ? '0' : '-1'" (click)="authService.GoogleAuth()">Sign in with Google</a>
			<div class="text-xl font-normal mt-2">Sign in to track your progress and<br>get Certificates of Completion</div>
		</li>
	</ul>

	<ul class="border-t border-black/20 mt-6 pt-6 md:mt-8 md:pt-8 lg:mt-12 lg:pt-12">
		<li><a [tabIndex]="menuActive ? '0' : '-1'" routerLink="/about" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">{{ cmsDataService.configuration.globalStrings.aboutTitle | titlecase }}</a></li>
		<li *ngFor="let anchor of cmsDataService.configuration.menu"><a [tabIndex]="menuActive ? '0' : '-1'" href="{{ anchor.url }}" target="{{ anchor.target }}" [attr.rel]="anchor.external ? 'external' : null">{{ anchor.label }}</a></li>
	</ul>

</nav>

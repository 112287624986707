import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { BehaviorSubject, catchError, forkJoin, from, map, Observable, of, Subscription, switchMap, take } from 'rxjs';
import { CuePoint } from 'src/app/shared/interfaces/cuepoint';
import { environment } from 'src/environments/environment';
import { Configuration } from '../interfaces/configuration';
import { Course } from '../interfaces/course';
import { CreditSection } from '../interfaces/creditsection';
import { Lesson } from '../interfaces/lesson';
import { MenuLink } from '../interfaces/menulink';
import { Poll } from '../interfaces/poll';
import { PollChoice } from '../interfaces/pollchoice';
import { LoggerService } from './logger.service';
import { UserdataService } from './userdata.service';

@Injectable({
	providedIn: 'root'
})
export class CMSDataService {
	logger: LoggerService = inject(LoggerService);

	configuration: Configuration = {
		menu: [],
		globalStrings: {
			environmentNotice: 'Please adjust the audio volume on your device.',
			aboutTitle: 'About this Site',
			aboutText: '',
			coreValuesTitle: 'Header Goes Here Lorem Ipsum',
			coreValuesText: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed dapibus hendrerit quam eu pharetra. 
				Proin pretium lorem vel dolor aliquet condimentum. Morbi at orca ac sem euismod molestie. 
				Morbi justo ipsum, iaculis ac efficitur sit amet, ultrices sed tellus.`,
		},
		signIn: {
			title: 'Welcome',
			description: 'Sign in with Google to track your progress through this course and download Certificates for completed lessons.',
		},
		introduction: {
			id: '0',
			slug: 'introduction',
			title: 'Congressional Medal of Honor',
			description: `The Medal of Honor is the United States’ highest award for valor in combat. 
				Fewer than 3600 men and women have been honored to wear the Medal for their actions above and beyond the call of duty.`,
			vimeoID: '0', // 769042731 test 10s video
			cmsKey: 0,
			completed: false,
			isIntroduction: true,
			photoURL: '',
			endRoute: '/lessons',
		},
		courses: [
			{
				topic: 'Courage',
				slug: 'courage',
				cmsKey: 0,
				description: '',
				photoURL: 'fallback/course.jpg',
				lessons: [],
			},
			{
				topic: 'Sacrifice',
				slug: 'sacrifice',
				cmsKey: 0,
				description: '',
				photoURL: 'fallback/course.jpg',
				lessons: [],
			},
			{
				topic: 'Patriotism',
				slug: 'patriotism',
				cmsKey: 0,
				description: '',
				photoURL: 'fallback/course.jpg',
				lessons: [],
			},
			{
				topic: 'Citizenship',
				slug: 'citizenship',
				cmsKey: 0,
				description: '',
				photoURL: 'fallback/course.jpg',
				lessons: [],
			},
			{
				topic: 'Integrity',
				slug: 'integrity',
				cmsKey: 0,
				description: '',
				photoURL: 'fallback/course.jpg',
				lessons: [],
			},
			{
				topic: 'Commitment',
				slug: 'commitment',
				cmsKey: 0,
				description: '',
				photoURL: 'fallback/course.jpg',
				lessons: [],
			},
		]
	};

	private validLessonIDs: string[] = [];

	private _dataLoaded: BehaviorSubject<boolean>;
	public get dataLoaded(): Observable<boolean> { this.logger.log('get dataLoaded(): ', this._dataLoaded); return this._dataLoaded.asObservable(); }

	private _cmsDataEvent: BehaviorSubject<string> = new BehaviorSubject<string>('');
	public get cmsDataEvent(): Observable<string> { this.logger.log('get cmsDataEvent(): ', this._cmsDataEvent);return this._cmsDataEvent.asObservable(); }//

	storagePath: string = '';
	//storagePath: string = 'alpha/';
	//storagePath: string = 'test/';

	//jsonPrefix: string = '';
	jsonPrefix: string = 'section_';

	//filesPath: string = '';
	filesPath: string = 'files/';


	constructor(
		private http: HttpClient,
		private storage:AngularFireStorage,
		private firestore:AngularFirestore,
		private auth:AngularFireAuth,
		private userdataService: UserdataService
		) {
		this._dataLoaded = new BehaviorSubject<boolean>(false);
	}

	isUserLoggedIn(): Observable<boolean> {
		return this.auth.authState.pipe(map(user => !!user));
	}
	userState?: Subscription;

	getCompletedLessonIDs(): Observable<string[]> {
		//return of([]);
		///AngularFireAuth is unavailable during APP_INITIALIZER
		return this.isUserLoggedIn().pipe(switchMap(isLoggedIn => {
			this.userdataService.resetLessonData();
			if (!isLoggedIn) {
				// User is not logged in, return an empty array
				return of([]);
			} else {
				// User is logged in, return array of completed lesson IDs
				return from(this.auth.currentUser).pipe(
					switchMap(user => {
						return this.firestore.collection('users').doc(user!.uid).collection('lessons').valueChanges({idField : 'id'}).pipe(
							map(lessons => lessons.map((lesson) => {
								this.logger.log('%cFIRESTORE READ', 'color: #000; background-color: #ffcc33;', 'CMSDataService: getCompletedLessonIDs()', lesson.id, lesson['prompt'] || '');
								
								// only pass valid lesson IDs to the userdataService
								if (this.isValidLessonID(lesson.id)) {
									this.userdataService.setUserLessonData(lesson.id, lesson['prompt']);
								}
								return lesson.id;
							})),
							catchError(error => {
								this.logger.error(error);
								return of([]);
							})
						);
					})
				)
			}
		}));
	}


	loadConfigurationFromCMS(): Promise<any> {
		const appTitle: string = `Congressional Medal of Honor Society | Path to Honor`;

		console.info( `%c${appTitle.toUpperCase()} | v${environment.appVersion}`, `
			color: #c3b58f; font-size: 1.2em; font-weight: bold; text-align: center;
			display: inline-block; padding: 1em 3em; margin: 1em 0; 
			border-top: 5px solid #c3b58f; border-bottom: 5px solid #c3b58f;
			background-color: #1f1f1f;
		`);

		this.logger.log('*** loadConfigurationFromCMS ***');

		return new Promise((resolve) => {

			// *** GET URLs of CMS JSON FILES ***
			forkJoin([
				this.storage.ref(`${this.storagePath}${this.jsonPrefix}2.json`).getDownloadURL(),
				this.storage.ref(`${this.storagePath}${this.jsonPrefix}3.json`).getDownloadURL(),
				this.storage.ref(`${this.storagePath}${this.jsonPrefix}4.json`).getDownloadURL(),
				//this.storage.ref(`${this.storagePath}${this.jsonPrefix}5.json`).getDownloadURL(),
				this.storage.ref(`${this.storagePath}${this.jsonPrefix}6.json`).getDownloadURL(),
				//this.storage.ref('media3.json').getDownloadURL(),
			]).subscribe(([
				url2,
				url3,
				url4,
				//url5,
				url6,
				//urlMedia3,
			]) => {

				// *** PARSE THE CMS JSON FILES ***
				forkJoin([
					this.http.get(url3, {responseType: 'json'}) as Observable<any>,
					this.http.get(url2, {responseType: 'json'}) as Observable<any>,
					this.http.get(url4, {responseType: 'json'}) as Observable<any>,
					//this.http.get(url5, {responseType: 'json'}) as Observable<any>,
					this.http.get(url6, {responseType: 'json'}) as Observable<any>,
					//this.http.get(urlMedia3, {responseType: 'json'}) as Observable<any>,
				])
				.subscribe(([
						courses,
						welcome,
						about,
						//certificate,
						menu,
						//media3,
						//uploadTest,
					]) => {
					//this.logger.log('*2 WELCOME: ', welcome);
					//this.logger.log('*3 COURSES: ', courses);
					//this.logger.log('*4 ABOUT HTML: ', about);
					//this.logger.log('*5 CERTIFICATE GLOBALS: ', certificate);
					//this.logger.log('*6 ADD MENU LINKS: ', menu);
					//this.logger.log('*MEDIA (3 COURSES): ', media3);

					// PARSE *2 INTRODUCTION + SIGNIN
					this.configuration.introduction.id = '0000_' + welcome[0]['Key'],
					this.configuration.introduction.description = welcome[0]['bodyCopyPriorToPressingPlay'];
					this.configuration.introduction.title = welcome[0]['titlePriorToPressingPlay'];
					this.configuration.introduction.vimeoID = welcome[0]['videoVimeoId'];
					this.configuration.globalStrings.environmentNotice = welcome[0]['bodyCopySpecial'];

					let cmsIntroPolls: Array<any> = welcome[0]['polling'] || [];
					if (cmsIntroPolls.length) {

						this.configuration.introduction.cuePoints = [];
						this.configuration.introduction.polls = []; //lesson.polls = {};

						cmsIntroPolls.forEach(cmsPoll => {
							let cmsPollAnswers: Array<any> = cmsPoll['answers'];
							if (cmsPollAnswers.length) {
								let pollKeyID: string = this.configuration.introduction.id + '_' + cmsPoll['Key'];

								//this.configuration.introduction.cuePoints = [];
								//this.configuration.introduction.polls = [];

								let cuePoint: CuePoint = {
									time: cmsPoll['pollPausePoint'],
									key: 'poll',
									value: pollKeyID,
								};
								this.configuration.introduction.cuePoints?.push(cuePoint);

								let poll: Poll = {
									description: cmsPoll['pollQuestion'],
									cmsKey: cmsPoll['Key'],
									id: pollKeyID,
									choices: [],
								};

								cmsPollAnswers.forEach(cmsPollAnswer => {
									let pollChoice: PollChoice = {
										description: cmsPollAnswer['choiceText'],
										cmsKey: cmsPollAnswer['Key'],
										id: pollKeyID + '_' + cmsPollAnswer['Key'],
										photoURL: cmsPollAnswer['choiceImage'] ? this.storagePath + this.filesPath + cmsPollAnswer['choiceImage'] : 'fallback/pollchoice.jpg',
									};
									poll.choices.push(pollChoice);
								});

								this.configuration.introduction.polls?.push(poll);


							}
						});

					}

					// // ---- SIGNIN
					this.configuration.signIn.title = welcome[0]['title'];
					this.configuration.signIn.description = welcome[0]['body'];

					// PARSE *6 ADD MENU LINKS
					menu[0]['additionalMenuLinks'].forEach((menuitem: any) => {
						//this.logger.log('MENUITEM', menuitem);
						let menuLink: MenuLink = {
							'label' : menuitem['label'],
							'url': menuitem['url'],
							'target': '_blank',
							'external': true,
						};
						this.configuration.menu.push(menuLink);
					});

					// PARSE *3 COURSES
					//const slugs: Array<string> = ['','','','','',''];
					this.configuration.globalStrings.coreValuesTitle = courses[0]['mainMenuHeading'];
					this.configuration.globalStrings.coreValuesText = courses[0]['mainMenuDescription'];

					this.configuration.courses.forEach(course => {
						if (undefined === courses[0][course.slug]) return;
						let cmsCourse: any = courses[0][course.slug];
						//this.logger.log('cmsCourse: ', cmsCourse);
						course.cmsKey = cmsCourse['Key'];
						course.description = cmsCourse['briefDescription'];
						course.photoURL = cmsCourse['courseImage'] ? this.storagePath + this.filesPath + cmsCourse['courseImage'] : 'fallback/course.jpg';

						let cmsLessons: Array<any> = cmsCourse['lessons'];
						cmsLessons.forEach(cmsLesson => {
							let lesson: Lesson = {
								id: course.cmsKey + '_' + cmsLesson['Key'],
								completed: false,
								//completed: Math.random() < 0.5,
								slug: this.slugify(cmsLesson['lessonTitle']),
								title: cmsLesson['lessonTitle'],
								subtitle: cmsLesson['secondarytitle'],
								description: cmsLesson['description'],
								cmsKey: cmsLesson['Key'],
								finalQuestion: cmsLesson['certificateQuestion'],
								vimeoID: cmsLesson['videoVimeoId'],
								//photoURL: undefined,
								photoURL: cmsLesson['photo'] ? this.storagePath + this.filesPath + cmsLesson['photo'] : 'fallback/course.jpg',
							};
							this.validLessonIDs.push(lesson.id);

							let cmsPolls: Array<any> = cmsLesson['polling'];
							if (cmsPolls.length) {

								lesson.cuePoints = [];
								lesson.polls = []; //lesson.polls = {};

								cmsPolls.forEach(cmsPoll => {
									let cmsPollAnswers: Array<any> = cmsPoll['answers'];
									if (cmsPollAnswers.length) {
										let pollKeyID: string = lesson.id + '_' + cmsPoll['Key'];

										//lesson.cuePoints = [];
										//lesson.polls = [];

										let cuePoint: CuePoint = {
											time: cmsPoll['pollPausePoint'],
											key: 'poll',
											value: pollKeyID,
										};
										lesson.cuePoints?.push(cuePoint);

										let poll: Poll = {
											description: cmsPoll['pollQuestion'],
											cmsKey: cmsPoll['Key'],
											id: pollKeyID,
											choices: [],
										};

										cmsPollAnswers.forEach(cmsPollAnswer => {
											let pollChoice: PollChoice = {
												description: cmsPollAnswer['choiceText'],
												cmsKey: cmsPollAnswer['Key'],
												id: pollKeyID + '_' + cmsPollAnswer['Key'],
												photoURL: cmsPollAnswer['choiceImage'] ? this.storagePath + this.filesPath + cmsPollAnswer['choiceImage'] : 'fallback/pollchoice.jpg',
											};
											poll.choices.push(pollChoice);
										});

										lesson.polls?.push(poll);


									}
								});

							}

							course.lessons.push(lesson);
						});

					});

					
					/*
					this.getCompletedLessonIDs()
					.pipe(take(1))
					.subscribe((lessonIDs: string[]) => {
						this.logger.log('STARTUP COMPLETED LESSONS', lessonIDs);
						this.configuration.courses.forEach(course => {
							course.lessons.forEach(lesson => {
								lesson.completed = false;
								if (lessonIDs.includes(lesson.id)) lesson.completed = true;
							});
						});
						this._cmsDataEvent.next('updateCompletedLessons');
					});
					*/

					this.userState = this.isUserLoggedIn().subscribe(() => {
						this.getCompletedLessonIDs()
							.pipe(take(1))
							.subscribe((lessonIDs: string[]) => {
								this.logger.log('USER STATE - COMPLETED LESSONS', lessonIDs);
								this.configuration.courses.forEach(course => {
									course.lessons.forEach(lesson => {
										lesson.completed = false;
										if (lessonIDs.includes(lesson.id)) lesson.completed = true;
									});
								});
								this._cmsDataEvent.next('updateCompletedLessons');
							});
					});
					
					/*
					// PARSE *5 CERTIFICATE GLOBALS
					this.configuration.globalStrings.certificateTitle = "Certificate of Completion"; //certificate[0].title;
					this.configuration.globalStrings.certificatePresentation = "This certificate is presented to"; //certificate[0].bodyCopyAboveName;
					this.configuration.globalStrings.certificateAccolade = "for successful completion of"; //certificate[0].bodyCopyAboveLesson;
					*/

					// PARSE *4 ABOUT HTML
					this.configuration.globalStrings.aboutTitle = about[0].title;
					this.configuration.globalStrings.aboutText = about[0].body;
					const aboutVideoVimeoId: number = (+about[0].videoVimeoId) ?? 0;
					if (aboutVideoVimeoId > 0) this.configuration.globalStrings.aboutVimeoID = about[0].videoVimeoId;
					
					// PARSE *4 CREDITS
					let cmsSponsorShip: Array<any> | undefined = about[0]?.sponsorship;
					if (null != cmsSponsorShip) {
						this.configuration.sponsorship = {
							attribution: cmsSponsorShip[0].sponsorshipText ?? '',
							logoURL: {
								default: cmsSponsorShip[0].sponsorshipLogoColor ? this.storagePath + this.filesPath + cmsSponsorShip[0].sponsorshipLogoColor : '',
								dark: cmsSponsorShip[0].sponsorshipLogoDark ? this.storagePath + this.filesPath + cmsSponsorShip[0].sponsorshipLogoDark : '',
								light: cmsSponsorShip[0].sponsorshipLogoLight ? this.storagePath + this.filesPath + cmsSponsorShip[0].sponsorshipLogoLight : ''
							}
						}
					}

					this.configuration.credits = {
						heading: about[0].creditsTitle,
						section: [],
					};
					//let cmsCredits: Array<any> = about[0].credits;
					let cmsCreditSections: Array<any> = about[0].creditSections;
					cmsCreditSections?.forEach((cmsCreditSection: any) => {
						let creditSection: CreditSection = {
							heading: cmsCreditSection.sectionTitle,
							item: [],
						};
						let cmsCreditItems: Array<any> = cmsCreditSection?.items;
						cmsCreditItems.forEach((cmsCreditItem: any) => {
							creditSection.item?.push(cmsCreditItem.item);
						});
						this.configuration.credits?.section?.push(creditSection);
					});


					// done loading?
					/*
					this.logger.log(
						`
						********************************
						THE FINAL CONFIGURATION OBJECT :
						********************************
						`, this.configuration);
					*/
					this._dataLoaded.next(true);
					this._cmsDataEvent.next('updateCompletedLessons');
					resolve(true);
				});

			});

		});

	}

	getLesson(topicSlug: string, lessonSlug: string): Lesson | undefined {
		this.logger.log('getLesson', topicSlug, lessonSlug);
		if ('introduction' === topicSlug) {
			return this.configuration.introduction;
		} else {
			let iLesson: number = -1;
			const iTopic: number = this.configuration.courses.findIndex(k => k.slug === topicSlug);
			if (iTopic > -1)
				iLesson = this.configuration.courses[iTopic].lessons.findIndex(k => k.slug === lessonSlug);

			//this.logger.log(iTopic, iLesson);

			if (iTopic > -1 && iLesson > -1)
				return this.configuration.courses[iTopic].lessons[iLesson];
		}

		return undefined;
	}

	isValidLessonID(id: string): boolean {
		return this.validLessonIDs.includes(id);
	}

	getCourse(topicSlug: string): Course | undefined {
		const iTopic: number = this.configuration.courses.findIndex(k => k.slug === topicSlug);
		if (iTopic > -1) return this.configuration.courses[iTopic];

		return undefined;
	}

	getTopic(topicSlug: string): string {
		const iTopic: number = this.configuration.courses.findIndex(k => k.slug === topicSlug);
		if (iTopic > -1) return this.configuration.courses[iTopic].topic;

		return '';
	}

	slugify(s: string): string {
		return s.toLowerCase()
		.replace(/\s+/g, '-') // Replace spaces with -
		.replace(/[^\w\-]+/g, '') // Remove all non-word characters
		.replace(/\-\-+/g, '-') // Replace multiple - with single -
		.replace(/^-+/, '') // Trim - from start of text
		.replace(/-+$/, '') // Trim - from end of text
	}
}

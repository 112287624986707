<section class="max-w-4xl px-6 md:px-0">
    <h2>What Do You Think?</h2>
    <p>{{ this.prompt }}</p>
</section>
<div class="w-full md:max-w-2xl px-6 md:px-0">
    <div class="mb-6 text-2xl">
        <textarea rows="6" placeholder="Enter your response here."
                  [(ngModel)]="answer"
                  (ngModelChange)="onModelChange($event)"
                  tabindex="{{ tabIndexValue }}"
                  [attr.maxlength]="counter ? maxChar : ''"></textarea>
        <div class="text-xl text-left">{{char}} / {{maxChar}}</div>
    </div>

    <div class="flex flex-col space-y-6 md:space-y-0 md:space-x-6 mt-6">
        <app-button tabIndexValue="{{ tabIndexValue }}" class="shy smwide" [disabled]="continueDisabled" [icon]="icons.ARROW" ariaLabelOverride="Save my response and continue" label="Continue" [command]="'continue'" (click)="closePrompt($event)"></app-button>
    </div>
</div>

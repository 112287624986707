import { Injectable, inject } from '@angular/core';
import { Router } from '@angular/router';
import { User } from '../interfaces/user';
import { GoogleAuthProvider, User as UserAccount } from 'firebase/auth';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore, AngularFirestoreDocument, QueryDocumentSnapshot } from '@angular/fire/compat/firestore';
import { UserdataService } from "./userdata.service";
import { LoggerService } from './logger.service';

@Injectable({
	providedIn: 'root',
})
export class AuthService {
	logger: LoggerService = inject(LoggerService);
	
	userDoc?: AngularFirestoreDocument;
	userAcct?: UserAccount;

	constructor(
		public afs: AngularFirestore, // Inject Firestore service
		public afAuth: AngularFireAuth, // Inject Firebase auth service
		private router: Router,
		private userdataService: UserdataService
		) {
			/* Saving user data in sessionStorage when
			logged in and setting up null when logged out */
			this.afAuth.setPersistence('session');
			this.afAuth.authState.subscribe((user) => {
				if (user) {
					this.SetUserData(user)
				} else {
					sessionStorage.setItem('user', 'null');
				}
			})
		}
		// Returns true when user is logged in
		get isLoggedIn(): boolean {
			const user = JSON.parse(sessionStorage.getItem('user')!);
			return user !== null ? true : false;
		}
		// Sign in with Google
		GoogleAuth() {
			return this.AuthLogin(new GoogleAuthProvider())
		}
		// Auth logic to run auth providers
		async AuthLogin(provider: GoogleAuthProvider) {
			try {
				const result = await this.afAuth
					.signInWithPopup(provider);
				this.SetUserData(result.user)
					.then(() => {
						this.userdataService.continueToApp();
					})
					.then(() => {
						this.router.navigate(['/lessons']);
					});
			} catch (error) {
				this.logger.error(error);
			}
		}
		/* Setting up user data when sign in with username/password,
		sign up with username/password and sign in with social auth
		provider in Firestore database using AngularFirestore + AngularFirestoreDocument service */
		SetUserData(user: any) {
			let promise = new Promise((resolve) => {
				this.userAcct = user;
				sessionStorage.setItem('user', JSON.stringify(this.userAcct));
				this.userDoc = this.afs.doc(`users/${user.uid}`);
				this.userDoc.ref.get().then((snapshot: QueryDocumentSnapshot<any>) => {
					this.logger.log('%cFIRESTORE READ', 'color: #000; background-color: #ffcc33;', 'SetUserData: userDoc');
					if (snapshot.exists) {
						let userData = snapshot.data();
						sessionStorage.setItem('userData', JSON.stringify(userData));
						this.logger.log('*SET LOCAL USER DATA', userData);
						resolve(snapshot.data());
					} else {
						const userData: User = {
							uid: user.uid,
							email: user.email,
							displayName: user.displayName,
						};
						this.userDoc?.set(userData, {merge: true});
						this.logger.log('%cFIRESTORE WRITE', 'background-color: #660000; color: #ffffff', 'SetUserData: userDoc');
						resolve(userData)
					}
				})
			});
			return promise
		}
		// Sign out
		async SignOut() {
			await this.afAuth.signOut();
			this.userdataService.resetUserData();
			this.router.navigate(['/welcome']);
		}
	}

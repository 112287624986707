import { Injectable, inject } from '@angular/core';
import { AngularFirestore } from "@angular/fire/compat/firestore";
import { Router } from "@angular/router";
import { LessonData } from '../interfaces/lessondata';
import { UserData } from '../interfaces/userdata';
import { UserLessonData } from '../interfaces/userlessondata';
import { LoggerService } from './logger.service';
import { serverTimestamp } from '@angular/fire/firestore';

@Injectable({
	providedIn: 'root'
})
export class UserdataService {
	logger: LoggerService = inject(LoggerService);

	userLessonData: UserLessonData = {};

	private initialUserData: UserData = {
		continueToApp: false,
		introComplete: false
	};
	
	constructor(
		public afs: AngularFirestore,
		private router: Router
	 ) {
		const user = JSON.parse(sessionStorage.getItem('user')!);
		if ( user !== null ) {
			this.logger.log('*USER EXISTS');
			this.continueToApp();
		} else {
			this.logger.log('*USER NULL');
			this.resetUserData();
		}
	}

	getUserId() {
		return this.getUserData().uid;
	}
	
	getUserData(): UserData {
		const userData = JSON.parse(sessionStorage.getItem('userData')!);
		this.logger.log('*GET LOCAL USER DATA',userData);
		return userData;
	}
	
	welcomeRouteGuard(): void {
		const userData = this.getUserData();
		this.logger.log('*WELCOME ROUTE GUARD',userData);
		if (userData.continueToApp) {
			if (userData.introComplete) {
				this.router.navigate(['/lessons'], {replaceUrl: true});
			} else {
				this.router.navigate(['/introduction'], {replaceUrl: true});
			}
		}
	}
	
	introRouteGuard(): void {
		const userData = this.getUserData();
		this.logger.log('*INTRO ROUTE GUARD',userData);
		if (!userData.continueToApp) {
			this.router.navigate(['/welcome'], {replaceUrl: true});
		}
		// if (userData.introComplete) {
		// 	this.router.navigate(['/lessons'], {replaceUrl: true});
		// }
	}
	
	lessonRouteGuard(): void {
		const userData = this.getUserData();
		this.logger.log('*LESSON ROUTE GUARD',userData);
		if (!userData.introComplete) {
			this.router.navigate(['/introduction'], {replaceUrl: true});
		}
	}
	
	certificatesRouteGuard(): void {
		const uid = this.getUserId();
		if (!uid) {
			this.router.navigate(['/lessons'], {replaceUrl: true});
		}
	}
	
	continueToApp(): void {
		const userData = this.getUserData();
		userData.continueToApp = true;
		this.logger.log('*CONTINUE TO APP',userData);
		this.updateUserData(userData);
	}

	updateDisplayName(name?: string): void {
		const userData = this.getUserData();
		userData.displayName = name;
		this.logger.log('*UPDATE DISPLAY NAME',userData);
		this.updateUserData(userData);
	}
	
	introComplete(): void {
		const userData = this.getUserData();
		userData.introComplete = true;
		this.logger.log('*INTRO COMPLETE',userData);
		this.updateUserData(userData);
	}
	
	updateUserData(userData: UserData) {
		this.logger.log('*UPDATE USER DATA',userData);
		const user = JSON.parse(sessionStorage.getItem('user')!);
		const dataChanged: boolean = JSON.stringify(this.getUserData()) !== JSON.stringify(userData);
		sessionStorage.setItem('userData', JSON.stringify(userData));
		if (user && dataChanged) {
			this.logger.log('%cFIRESTORE WRITE', 'background-color: #660000; color: #ffffff', 'updateUserData');
			this.afs.doc(`users/${user.uid}`).set(userData, {merge: true});
		}
	}
	
	setUserLessonData(lessonId: string, promptText: string): void {
		this.userLessonData[lessonId] = {
			'prompt': promptText
		};
		this.logger.log('*SET LOCAL USER LESSON DATA', lessonId, promptText);
	}
	
	updateLessonProgress(lessonId: string, lessonData: LessonData) {
		this.logger.log('*UPDATE LESSON PROGRESS',lessonData);
		const uid = this.getUserId();
		this.userLessonData[lessonId] = lessonData;
		if (uid) {
			this.logger.log('%cFIRESTORE WRITE', 'background-color: #660000; color: #ffffff', 'updateLessonProgress (updates the prompt response)');
			lessonData.t = serverTimestamp();
			this.afs.doc(`users/${uid}`).collection(`lessons`).doc(`${lessonId}`).set(lessonData, {merge: true});
		}
	}
	
	resetLessonData(): void {
		this.userLessonData = {};
	}

	resetUserData(): void {
		this.logger.log('*RESET USER DATA');
		sessionStorage.setItem('userData', JSON.stringify(this.initialUserData));
	}
}

import { Component, Input, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { Icons } from 'src/app/shared/enums/icons';
import { Course } from 'src/app/shared/interfaces/course';
import { Lesson } from 'src/app/shared/interfaces/lesson';
import { CMSDataService } from 'src/app/shared/services/cmsdata.service';
import { UserdataService } from "../../../../shared/services/userdata.service";
import { environment } from 'src/environments/environment';
import { Subscription } from 'rxjs';
import { animate, query, stagger, style, transition, trigger } from '@angular/animations';
import { HeaderService } from 'src/app/shared/services/header.service';

@Component({
	selector: 'app-lessonlist',
	templateUrl: './lessonlist.component.html',
	styleUrls: ['../../page/page.component.scss', './lessonlist.component.scss'],
	animations: [
		trigger( 'blockAnimation', [
			transition('void => *', [
				query(':enter',	[
					style({ opacity: 0, transform: 'translateY(-25px)' }),
					stagger(150, [
						animate('0.6s ease-out',
							style({ opacity: 1, transform: 'translateY(0)' })
						)
					]),
				]),
			]),
		]),
	],
})
export class LessonlistComponent implements OnInit {

	@Input() course: Course | undefined;

	lessonsTotal: number = 0;
	courseProgress: number = 0;

	menuActive: boolean = false;

	private _lessonsCompleted: number = 0;
	public get lessonsCompleted(): number { return this._lessonsCompleted; }
	@Input() public set lessonsCompleted(value: number) {
		this._lessonsCompleted = value;
	}

	subscriptions: Subscription[] = [];

	icons = Icons;

	constructor(
		public cmsDataService:CMSDataService,
		private route: ActivatedRoute,
		private router: Router,
		private titleService: Title,
		private userdataService: UserdataService,
		public headerService: HeaderService,
	) {
		this.userdataService.lessonRouteGuard();

		const topicSlug: string = this.route.snapshot.paramMap.get('topic') || '';

		this.course = cmsDataService.getCourse(topicSlug);

		if (!this.course) {
			this.router.navigateByUrl('/lessons'); // lesson doesn't exist, return to courses
		}



		/*
		if (this.course?.lessons.length === 1) { // single lesson in course
			this.router.navigateByUrl(
				this.generateLessonPath(this.course.lessons[0]),
				{
					replaceUrl: true, // replace state in history (Back will return to course selection grid)
				}
			);
		}
		*/
	}

	ngOnInit(): void {
		this.titleService.setTitle(`${environment.appTitlePrefix}${this.course?.topic} Lessons | CMOHS Path to Honor`);

		this.lessonsTotal = this.course?.lessons.length ?? 1;
		this.updateCompletedLessons();

		this.subscriptions.push(
			this.cmsDataService.cmsDataEvent.subscribe(event => {
				if ('updateCompletedLessons' === event) {
					this.updateCompletedLessons();
				}
			}),
			this.headerService.menuActive().subscribe( value => this.menuActive = value ),
		);

	}

	ngOnDestroy(): void {
		this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
	}

	updateCompletedLessons(): void {
		/*
		this.lessonsCompleted = 0;
		this.course?.lessons.forEach((lesson: Lesson) => {
			if (lesson.completed) {
				this.lessonsCompleted++;
			}
		});
		*/

		let lc: number = 0;
		this.course?.lessons.forEach((lesson: Lesson) => {
			if (lesson.completed) {
				lc++;
			}
		});
		this.lessonsCompleted = lc;
		this.courseProgress = this.lessonsCompleted / this.lessonsTotal * 100;
	}

	generateLessonPath(lesson: Lesson): string {
		return '/lessons/' + this.cmsDataService.slugify(this.course?.topic || '') + '/' + this.cmsDataService.slugify(lesson.title);
	}

}

import {Component, EventEmitter, Input, OnInit, Output, inject} from '@angular/core';
import { Icons } from 'src/app/shared/enums/icons';
import { LessonService } from 'src/app/shared/services/lesson.service';
import { LoggerService } from 'src/app/shared/services/logger.service';
import { UserdataService } from 'src/app/shared/services/userdata.service';

@Component({
	selector: 'app-certification',
	templateUrl: './certification.component.html',
	styleUrls: ['./certification.component.scss']
})
export class CertificationComponent implements OnInit {
	logger: LoggerService = inject(LoggerService);

	@Input() tabIndexValue: string = '0';

	name?: string = this.userdataService.getUserData().displayName;
	lessonTopic: string = this.lessonService.currentLesson ? this.lessonService.currentTopic : '';
	lessonTitle: string = this.lessonService.currentLesson ? this.lessonService.currentLesson.title : '';
	lessonSubtitle: string = this.lessonService.currentLesson?.subtitle ? this.lessonService.currentLesson.subtitle : '';
	prompt: string = this.lessonService.currentLesson ? this.lessonService.currentLesson.finalQuestion! : '';
	answer: string = 'Lorem ipsum dolor sit amet.';
	certificateDisabled: boolean = true;

	@Output() onClick = new EventEmitter();

	userClosedCertification: boolean = false;

	icons = Icons;

	constructor(public lessonService: LessonService, public userdataService: UserdataService) {
		this.answer = this.lessonService.getPromptResponse();
		this.certificateDisabled = this.name ? false : true;
	}

	ngOnInit(): void {
	}

	closeCertification(event: Object): void {
		this.logger.log('Close Certification', event);
		this.userClosedCertification = true;
		this.userdataService.updateDisplayName(this.name);
		this.lessonService.closeCertification();
		this.lessonService.closeLesson();
	}

	editResponse(event: Object): void {
		this.logger.log('Edit Response', event);
		this.userdataService.updateDisplayName(this.name);
		this.lessonService.editResponse();
	}

	onModelChange(textValue: string): void {
		this.certificateDisabled = textValue.trim().length > 0 ? false : true
	}
}

import { Injectable, NgModule } from '@angular/core';
import { RouterModule, RouterStateSnapshot, Routes, TitleStrategy } from '@angular/router';
import { environment } from '../environments/environment';
import { LessonComponent } from './components/lesson/lesson.component';
import { WelcomeComponent } from './components/pages/welcome/welcome.component';
import { AboutComponent } from './components/pages/about/about.component';
import { CoursesComponent } from './components/pages/courses/courses.component';
import { Title } from '@angular/platform-browser';
import { CertificatesComponent } from './components/pages/certificates/certificates.component';
import { LessonlistComponent } from './components/pages/courses/lessonlist/lessonlist.component';
import { LessonNavGuard } from './shared/guards/lesson-nav.guard';

export const routes: Routes = [
	{ path: 'about', component: AboutComponent, title: 'About this Course' },
	{ path: 'certificates', component: CertificatesComponent, title: 'My Certificates', },
	{ path: 'welcome', component: WelcomeComponent, title: 'Welcome' },
	{ path: 'introduction', component: LessonComponent, title:'Introduction', canDeactivate: [LessonNavGuard] },
	{ path: 'lessons', component: CoursesComponent, title: 'Core Values' },
	{ path: 'lessons/:topic', component: LessonlistComponent, title: 'Lessons: Path to Honor' },
	{ path: 'lessons/:topic/:lesson', component: LessonComponent, title:'Path to Honor: Lesson Title', canDeactivate: [LessonNavGuard] },
	{ path: '**', redirectTo: 'welcome' },
];

@Injectable({providedIn: 'root'})
export class TemplatePageTitleStrategy extends TitleStrategy {
	constructor(private readonly title: Title) {
		super();
	}

	override updateTitle(routerState: RouterStateSnapshot) {
		const title = this.buildTitle(routerState);
		if (title !== undefined) {
			this.title.setTitle(`${environment.appTitlePrefix}${title} | CMOHS Path to Honor`);
		}
	}
}

@NgModule({
	imports: [RouterModule.forRoot(routes, {
		onSameUrlNavigation: 'reload'
	})],
	exports: [RouterModule],
	providers: [
		{provide: TitleStrategy, useClass: TemplatePageTitleStrategy},
	]
})
export class AppRoutingModule { }

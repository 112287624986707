import { Component, ElementRef, Input, OnInit, SimpleChanges, ViewChild, inject } from '@angular/core';
import { Alphabet } from 'src/app/shared/enums/alphabet';
import { PollChoice } from 'src/app/shared/interfaces/pollchoice';
import { gsap } from 'gsap';
import { Linear, Power4, Power2 } from 'gsap';
import { LoggerService } from 'src/app/shared/services/logger.service';

@Component({
	selector: 'app-choice',
	templateUrl: './choice.component.html',
	styleUrls: ['./choice.component.scss']
})
export class ChoiceComponent implements OnInit {
	logger: LoggerService = inject(LoggerService);

	@Input() choice?: PollChoice;
	@Input() choiceIndex: number = 0;
	@Input() percentage: number | null = null;
	@Input() disabled: boolean = false;
	@Input() selected: boolean = false;
	@Input() tabIndexValue: string = '0';

	tlResultDisplay!: gsap.core.Timeline;

	@ViewChild('results') private _results!: ElementRef;
	private get results(): HTMLElement { return this._results.nativeElement; }

	@ViewChild('percentbar') private _percentbar!: ElementRef;
	private get percentbar(): HTMLElement { return this._percentbar.nativeElement; }

	@ViewChild('percentvalue') private _percentvalue!: ElementRef;
	private get percentvalue(): HTMLElement { return this._percentvalue.nativeElement; }
	
	alphabet = Alphabet;

	constructor() { }
	
	ngOnInit(): void {
	}

	ngAfterViewInit(): void {
		//this.logger.log(this.percentage);
		this.tlResultDisplay = gsap.timeline({paused: true});
		this.tlResultDisplay
			.to(this.results, {duration: .5, opacity: 1, ease:Linear.easeNone}, 'start')
			.to(this.percentbar, {duration: .5, width: '100%', ease:Power4.easeIn}, '-=0.25')
			.to(this.percentvalue, {duration: 1, width: (): string => { return `${this.percentage}%` }, ease:Power2.easeOut})
		;
	}

	ngOnDestroy(): void {
		this.tlResultDisplay.kill();
	}

	ngOnChanges(changes:SimpleChanges): void {
		if (changes['percentage']?.previousValue === 0 && changes['percentage'].currentValue) {
			//this.logger.log('CHOICE PERCENTAGE', changes['percentage'].currentValue);

			this.tlResultDisplay.play();
		}
	}

	
}

import { Component, Input, OnInit, inject } from '@angular/core';
import { Subscription } from 'rxjs';
import { HeaderService } from 'src/app/shared/services/header.service';

@Component({
	selector: 'app-page',
	templateUrl: './page.component.html',
	styleUrls: ['./page.component.scss']
})
export class PageComponent implements OnInit {
	
	headerService: HeaderService = inject(HeaderService);

	@Input() menuActive: boolean = false;
	
	subscriptions: Subscription[] = [];
	
	constructor() { }
	
	ngOnInit(): void {
		this.subscriptions.push(
			this.headerService.menuActive().subscribe( value => this.menuActive = value ),
		);
	}

	ngOnDestroy(): void {
		this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
	}
	
}

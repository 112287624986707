import { Component, Input, OnInit } from '@angular/core';
import { jsPDF } from 'jspdf';
import { Icons } from 'src/app/shared/enums/icons';
import { CMSDataService } from 'src/app/shared/services/cmsdata.service';
//import { UserdataService } from "../../../shared/services/userdata.service";
import { imgData } from './imgData';

@Component({
	selector: 'app-get-certificate',
	templateUrl: './get-certificate.component.html',
	styleUrls: ['./get-certificate.component.scss']
})
export class GetCertificateComponent implements OnInit {
	@Input() class?: string;
	@Input() element?: string;
	@Input() icon?: Icons;
	@Input() download?: boolean = false;
	@Input() label?: string = this.download ? 'Download Certificate' : 'Get My Certificate';
	@Input() tabIndexValue: string = '0';
	@Input() disabled?: boolean = true;
	@Input() lessonTopic: string = 'Lesson Topic';
	@Input() lessonTitle: string = 'Lesson Title';
	@Input() lessonSubtitle: string = 'foo';
	@Input() name?: string; // = this.userdataService.getUserData().displayName;
	@Input() prompt: string = 'Lorem ipsum dolor sit amet?';
	@Input() answer: string = '';

	certificateCompleted: string = 'Completed';
	certificatePresentation: string = 'This certificate is presented to';

	imgData: string = imgData;

	icons = Icons;
	filename: string = 'SetFilename';

	constructor(
		public cmsDataService: CMSDataService,
		//private userdataService: UserdataService
	) {
	}

	ngOnInit() { }

	createCertificate(): jsPDF {
		const pdf = new jsPDF({
			orientation: 'landscape',
			unit: 'in',
			format: [8.5, 11],
		});
		this.filename = `Certificate of Completion - ${this.lessonTopic} - ${this.lessonTitle}` + (this.lessonSubtitle ? ` - ${this.lessonSubtitle}` : '');
		pdf.setProperties({
			title: this.filename,
			author: 'Congressional Medal of Honor Society',
			creator: 'Congressional Medal of Honor Society',
		});
		pdf.addFont('./assets/fonts/Champion-Bantamweight.ttf', 'Champion Bantamweight', 'normal', 700);
		// OTF converted to TTF
		pdf.addFont('./assets/fonts/gara.ttf', 'Garamond', 'normal', 400);
		pdf.addFont('./assets/fonts/garabd.ttf', 'Garamond', 'normal', 700);

		pdf.addImage(this.imgData, 'JPEG', 0, 0, 11, 8.5);

		// * bold serif text *
		pdf.setFont('Garamond', 'normal', 700);
		pdf.setTextColor('#293446');
		pdf.setFontSize(21);
		pdf.text(this.certificateCompleted.toUpperCase(), 5.5, 2.475, { align:'center' });

		// Name
		pdf.setFontSize(48);
		const renderedName: string = this.name!.replace(/\s+/g, ' ' ).trim() ?? '';
		pdf.text(renderedName, 5.5 + renderedName.length * 0.0125, 1.285, { align:'center', charSpace: -0.025 });

		// Final question text
		pdf.setFontSize(16);
		pdf.setTextColor('#1f2020');
		pdf.text(this.prompt.replace(/\s+/g, ' ' ).trim(), 5.5, 5.3, { align:'center', maxWidth: 8, lineHeightFactor:1.2 });

		// * serif text *
		pdf.setFont('Garamond', 'normal', 400);
		pdf.setFontSize(18);
		pdf.setTextColor('#000000');
		pdf.text(this.certificatePresentation, 5.5, 0.5, { align:'center' });

		// Answer
		pdf.setTextColor('#293446');
		pdf.text(this.answer.replace(/\s+/g, ' ' ).trim(), 5.5, 6.3, { align:'center', maxWidth: 8, lineHeightFactor:1.4 });

		// Lesson Topic, Title, Subtitle
		pdf.setFont('Champion Bantamweight', 'normal', 700);
		pdf.setFontSize(90);
		pdf.text(this.lessonTopic.toUpperCase(), 5.5, 3.95, { align:'center' });
		pdf.setFont('Garamond', 'normal', 400);
		pdf.setFontSize(18);
		pdf.text(this.lessonTitle, 5.5, 4.3, { align:'center' });
		pdf.text(this.lessonSubtitle ?? '', 5.5, 4.6, { align:'center' });

		return pdf;
	}

	generatePDF(data: any) {
		const pdf = this.createCertificate();
		switch(data.command) {
			case 'download':
				pdf.save(this.filename + '.pdf');
				break;

			default:
				window.open( pdf.output('bloburl') );
		}
	}
}

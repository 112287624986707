<section>
	<h1>My Certificates</h1>
	<p>
		Visit this page to download a Certificate of Completion for your completed lessons.
	</p>

	<section class="mb-12">
		<div class="course" *ngFor="let cc of completedLessonsForDisplay">
			<h2>{{ cc.title }}</h2>
			<nav>
				<ul>
					<li *ngFor="let lc of cc.lessons">
						<app-get-certificate [name]="displayName" tabIndexValue="{{ menuActive ? '-1' : '0' }}" class="link" element="anchor" [label]="lc.title" [lessonTopic]="cc.title" [lessonTitle]="lc.title" [lessonSubtitle]="lc.subtitle" [prompt]="lc.prompt" [answer]="lc.response" [disabled]="false"></app-get-certificate>
					</li>
				</ul>
			</nav>
		</div>
	</section>

	<app-button tabIndexValue="{{ menuActive ? '-1' : '0' }}" class="shy page smwide" element="anchor" [icon]="icons.LIST" label="View all Core Values" navigateTo="/lessons"></app-button>

</section>

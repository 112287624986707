import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: 'minuteSeconds'
  })
  export class MinuteSecondsPipe implements PipeTransform {
  
      transform(value: number): string {
         const minutes: number = Math.floor(value / 60);
         return minutes.toString() + ':' + 
             (value - minutes * 60).toString().padStart(2, '0');
      }
  }

import { Pipe, PipeTransform } from "@angular/core";

interface KeyPhraseFilter {
	search: RegExp;
	replace: string;
}

@Pipe({
	name: 'filterKeyPhrases'
})
export class FilterKeyPhrasesPipe implements PipeTransform {
	
	filters: KeyPhraseFilter[] = [
		{
			search: new RegExp(/Medal of Honor/, 'g'),
			replace: "Medal&nbsp;of&nbsp;Honor"
		},
		{
			search: new RegExp(/Path to Honor/, 'g'),
			replace: "Path&nbsp;to&nbsp;Honor"
		},
		{
			search: new RegExp(/Civil War/, 'g'),
			replace: "Civil&nbsp;War"
		},
		{
			search: new RegExp(/World War II/, 'g'),
			replace: "World&nbsp;War&nbsp;II"
		},
		{
			search: new RegExp(/World War I/, 'g'),
			replace: "World&nbsp;War&nbsp;I"
		},
		{
			search: new RegExp(/Vietnam War/, 'g'),
			replace: "Vietnam&nbsp;War"
		},
		{
			search: new RegExp(/Korean War/, 'g'),
			replace: "Korean&nbsp;War"
		},
		{
			search: new RegExp(/War on Terror/, 'g'),
			replace: "War&nbsp;on&nbsp;Terror"
		},
		{
			search: new RegExp(/War on Terrorism/, 'g'),
			replace: "War&nbsp;on&nbsp;Terrorism"
		},
	];

	transform(value: string | undefined): string | undefined {
		if (undefined === value) return undefined;

		let newvalue: string = value ?? '';

		this.filters.forEach((filter: KeyPhraseFilter) => {
			newvalue = newvalue.replace(filter.search, filter.replace);
		});

		return newvalue;
	}
}

import { Component, Input, OnInit, inject } from '@angular/core';
import { Subscription } from 'rxjs';
import { Icons } from 'src/app/shared/enums/icons';
import { Course } from 'src/app/shared/interfaces/course';
import { Lesson } from 'src/app/shared/interfaces/lesson';
import { CMSDataService } from 'src/app/shared/services/cmsdata.service';
import { HeaderService } from 'src/app/shared/services/header.service';
import { LoggerService } from 'src/app/shared/services/logger.service';

@Component({
	selector: 'app-course',
	templateUrl: './course.component.html',
	styleUrls: ['./course.component.scss'],
})
export class CourseComponent implements OnInit {
	logger: LoggerService = inject(LoggerService);

	@Input() course!: Course;

	lessonsTotal: number = 0;
	courseProgress: number = 0;

	menuActive: boolean = false;

	private _lessonsCompleted: number = 0;
	public get lessonsCompleted(): number { return this._lessonsCompleted; }
	@Input() public set lessonsCompleted(value: number) {
		this._lessonsCompleted = value;
	}

	subscriptions: Subscription[] = [];

	icons = Icons;
	
	constructor(public cmsDataService:CMSDataService, public headerService: HeaderService) {}
	
	ngOnInit(): void {
		this.lessonsTotal = this.course.lessons.length;
		this.updateCompletedLessons();

		this.subscriptions.push(
			this.cmsDataService.cmsDataEvent.subscribe(event => {
				if ('updateCompletedLessons' === event) {
				this.updateCompletedLessons();
				}
			}),
			this.headerService.menuActive().subscribe( value => this.menuActive = value ),
		);
	}

	ngOnDestroy(): void {
		this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
	}

	updateCompletedLessons(): void {
		let lc: number = 0;
		this.course.lessons.forEach((lesson: Lesson) => {
			if (lesson.completed) {
				lc++;
			}
		});
		this.lessonsCompleted = lc;
		this.courseProgress = this.lessonsCompleted / this.lessonsTotal * 100;
		//this.logger.log('lessonsCompleted', this.lessonsCompleted);
	}
	
	generateLessonPath(lesson: Lesson): string {
		return '/lessons/' + this.cmsDataService.slugify(this.course.topic) + '/' + this.cmsDataService.slugify(lesson.title);
	}
	
	generateTopicPath(): string {
		return '/lessons/' + this.cmsDataService.slugify(this.course.topic);
	}

	getButtonLabel(): string {
		let lwa: string = (this.lessonsCompleted > 0 ? ( this.lessonsCompleted === this.lessonsTotal ? 'Review' : 'Resume') : 'Start');
		let lwb: string = (this.lessonsTotal > 1 ? 'Course' : 'Lesson');

		if (this.lessonsTotal > 1) {
			lwa = 'View';
			lwb = 'Lessons';
		}

		return `${lwa} ${lwb}`;
	}

	getButtonIcon(): Icons {
		return this.lessonsTotal > 1 ? Icons.LIST : Icons.ARROW;
	}
}

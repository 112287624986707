import { Component, Input, OnInit, inject } from '@angular/core';
import { Icons } from 'src/app/shared/enums/icons';
import { LessonService } from 'src/app/shared/services/lesson.service';
import { gsap } from 'gsap';
import { Linear, Power4 } from 'gsap';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { PollResults } from 'src/app/shared/interfaces/pollresults';
import { increment } from '@angular/fire/firestore';
import { LoggerService } from 'src/app/shared/services/logger.service';

@Component({
	selector: 'app-poll',
	templateUrl: './poll.component.html',
	styleUrls: ['./poll.component.scss']
})
export class PollComponent implements OnInit {
	logger: LoggerService = inject(LoggerService);

	@Input() tabIndexValue: string = '0';

	pollID: string;
	userChoiceIndex: number | null = null;
	userChoiceLocked: boolean = false;
	resultsPending: boolean = true;
	userClosedPoll: boolean = false;
	resultsLoader: number[] = [20, 20, 20];
	resultsLoaderPct: number[] = [33, 33, 34];
	resultsDisplayPct: number[] = [0, 0, 0];

	pollResultsDocumentRef: AngularFirestoreDocument<PollResults>;

	tlPollHeading!: gsap.core.Timeline;
	tlResultSet!: gsap.core.Timeline;

	_pollHeading: string = 'Make a Selection';
	get pollHeading(): string {
		return this._pollHeading;
	}
	set pollHeading(value: string) {
		this._pollHeading = value;
		this.tlPollHeading.restart();
	}

	icons = Icons;

	constructor(public lessonService: LessonService, private firestore: AngularFirestore) {
		this.pollID = this.lessonService.currentPoll?.id || 'base';
		this.pollResultsDocumentRef = this.firestore.collection('pollResults').doc(this.pollID);
	}

	ngOnInit(): void {
		this.loadPollResults();

		this.tlPollHeading = gsap.timeline({paused: true});
		this.tlPollHeading
			.from("#pollheadingtext", {duration: 2, x: 30, ease:Power4.easeOut}, 'start')
			.from("#pollheadingtext", {duration: 2, opacity: 0, ease:Linear.easeNone}, 'start')
		;
		this.tlPollHeading.seek('-=0');

		this.tlResultSet = gsap.timeline({paused: true});
		this.tlResultSet
			.add(gsap.delayedCall(0, () => { this.resultsDisplayPct[0] = this.resultsLoaderPct[0] }))
			.add(gsap.delayedCall(.5, () => { this.resultsDisplayPct[1] = this.resultsLoaderPct[1] }))
			.add(gsap.delayedCall(.5, () => { this.resultsDisplayPct[2] = this.resultsLoaderPct[2] }))
			.add(gsap.delayedCall(2, () => { this.resultsPending = false }))
		;
	}

	ngOnDestroy(): void {
		this.tlPollHeading.kill();
		this.tlResultSet.kill();
	}

	onMadeChoice(index: number): void {
		if (this.userChoiceLocked) return;

		this.userChoiceIndex = index;
		this.logger.log('Made Poll Choice', this.userChoiceIndex);
	}

	showResults(event: Object): void {
		this.userChoiceLocked = true;

		const i: number = this.userChoiceIndex || 0;
		this.resultsLoader[i]++;
		if ('base' !== this.pollID) {
			this.logger.log('%cFIRESTORE WRITE', 'background-color: #660000; color: #ffffff', 'increment Poll choice');
			this.pollResultsDocumentRef.update({
				[`p${i}`]: increment(1)
			});
		}

		this.pollHeading = 'Here’s What Other People Selected';

		this.logger.log('Show Results', event);

		//this.resultsLoaderPct = this.tempRandomResults();
		this.resultsLoaderPct = this.resultsToPercentages();

		this.tlResultSet.play();
	}

	closePoll(event: Object): void {
		this.logger.log('Close Poll', event);

		this.userClosedPoll = true;

		this.lessonService.closePoll();
	}

	resultsToPercentages(): number[] {
		const total: number = this.resultsLoader[0] + this.resultsLoader[1] + this.resultsLoader[2];
		const p0: number = Math.floor(this.resultsLoader[0] / total * 100);
		const p1: number = Math.floor(this.resultsLoader[1] / total * 100);
		const p2: number = 100 - (p0 + p1);
		return [p0,p1,p2];
	}

	tempRandomResults(): number[] {
		const p0: number = Math.floor(Math.random() * 45) + 1;
		const p1: number = Math.floor(Math.random() * 45) + 1;
		const p2: number = 100 - (p0 + p1);
		return [p0,p1,p2];
	}

	loadPollResults(): void {
		this.pollResultsDocumentRef?.ref.get().then(snapshot => {

			let pollResultsData: PollResults;
			if (snapshot.exists) {
				this.logger.log('%cFIRESTORE READ', 'background-color: #ffcc33;', 'loadPollResults');
				pollResultsData = snapshot.data() as PollResults;
			} else {
				pollResultsData = {
					_LessonTitle: (this.lessonService.currentTopic ? this.lessonService.currentTopic + ': ' : '') + this.lessonService.currentLesson?.title,
					p0:0,p1:0,p2:0
				};
				this.logger.log('%cFIRESTORE WRITE', 'background-color: #660000; color: #ffffff', 'loadPollResults (initialize 1st polling)');
				this.pollResultsDocumentRef.set(pollResultsData);
			}

			this.resultsLoader = [pollResultsData.p0 + 20, pollResultsData.p1 + 20, pollResultsData.p2 + 20];
		});
	}


}

import { Component, OnInit } from '@angular/core'
import { UserdataService } from "../../../shared/services/userdata.service"
import { CMSDataService } from 'src/app/shared/services/cmsdata.service';
import { HeaderService } from 'src/app/shared/services/header.service';
import { Subscription } from 'rxjs';

@Component({
	selector: 'app-welcome',
	templateUrl: './welcome.component.html',
	styleUrls: ['./welcome.component.scss']
})
export class WelcomeComponent implements OnInit {

	//@Input() title?: string;
	//@Input() description?: string;

	menuActive: boolean = false;

	subscriptions: Subscription[] = [];

	constructor(
		private userdataService: UserdataService,
		public cmsDataService: CMSDataService,
		public headerService: HeaderService,
	) {
		//this.title = cmsDataService.configuration.signIn.title;
		//this.description = cmsDataService.configuration.signIn.description;
	}

	ngOnInit(): void {
		this.userdataService.welcomeRouteGuard();

		this.subscriptions.push(
			this.headerService.menuActive().subscribe( value => this.menuActive = value ),
		);
	}

	ngOnDestroy(): void {
		this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
	}

}

import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';

@Component({
	selector: 'app-progress-bar',
	templateUrl: './progress-bar.component.html',
	styleUrls: ['./progress-bar.component.scss']
})
export class ProgressBarComponent implements OnInit {
	
	@Input() lessonsTotal: number = 1;
	@Input() lessonsCompleted: number = 0;
	@Input() courseProgress: number = 0;

	@ViewChild('bar_dividers') barDividers!: ElementRef;
	
	constructor() { }
	
	ngOnInit(): void {}

	ngAfterViewInit(): void {
		this.drawDivisions();
	}
	
	drawDivisions(): void {
		for (let i = 1; i < this.lessonsTotal; i++) {
			const pct: number = i / this.lessonsTotal * 100;
			const marker: HTMLDivElement = document.createElement('div');
			marker.className = 'absolute w-[2px] h-1 bg-offwhite top-0 -translate-x-1/2';
			marker.style.left = `${pct}%`;
			this.barDividers.nativeElement.appendChild(marker);
		}
	}

}

<button tabindex="{{ tabIndexValue }}" class="choice" [attr.aria-label]="'Option ' + alphabet[choiceIndex] + '. ' + choice?.description"
	[attr.aria-pressed]="selected ? 'true' : 'false'" [ngClass]="[selected ? 'selected' : '', disabled ? 'disabled' : '']" [disabled]="disabled ? 'disabled' : null">
	<div class="choice-photo relative" style="background-image: url('{{ choice?.photoURL || '' | getDownloadURL }}')">
		<div #results aria-hidden="true" class="flex flex-col choice-photo-overlay bg-gradient-to-b from-black/40 to-black
				absolute left-0 top-0 w-full h-full opacity-0">
			<div class="flex-grow flex
					px-3 md:px-5 lg:px-6 xl:px-7 2xl:px-8
					pb-2 md:pb-5 lg:pb-6 xl:pb-7 2xl:pb-8
					">
				<h3 class="self-end uppercase mb-0
					text-4xl md:text-5xl
					"><span class="text-red-light">{{percentage}}%</span> chose this</h3>
			</div>
			<div #percentbar class="relative flex-shrink h-4 lg:h-8 w-0 bg-offwhite">
				<div #percentvalue class="absolute left-0 top-0 h-4 lg:h-8 w-0 bg-gradient-to-r from-red-dark to-red-light"></div>
			</div>
		</div>
	</div>
	<div class="choice-text">
		<div class="choice-index">{{ alphabet[choiceIndex] }}</div>
		<div class="choice-description">{{ choice?.description }}</div>
	</div>
</button>  

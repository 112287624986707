<section class="max-w-5xl">

	<div *ngIf="lessonService.currentLesson?.completed" class="mb-8 md:mb-12 flex flex-row text-base text-offwhite uppercase font-semibold text-center">
		<div class="bg-gradient-to-r from-black/0 to-black/90 flex-grow flex justify-end"><img class="spinastar w-6 aspect-square object-contain" src="./assets/img/star.png" alt="" /></div>
		<div class="bg-black/90 flex items-center px-5 pt-2 pb-1.5">Completed</div>
		<div class="bg-gradient-to-r from-black/90 to-black/0 flex-grow flex"><img class="spinastarev w-6 aspect-square object-contain" src="./assets/img/star.png" alt="" /></div>
	</div>

	<h3 *ngIf="lessonService.currentTopic">{{ lessonService.currentTopic }}</h3>
	<h1>{{ lessonService.currentLesson?.title }}</h1>
	<h2 class="subtitle" *ngIf="lessonService.currentLesson?.subtitle">{{ lessonService.currentLesson?.subtitle }}</h2>
	<p class="description" [innerHTML]="lessonService.currentLesson?.description | filterKeyPhrases"></p>
	<p class="environmentNotice italic">{{ cmsDataService.configuration.globalStrings.environmentNotice }}</p>
	<ng-content></ng-content>
</section>

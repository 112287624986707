import { Component, Input, OnInit } from '@angular/core'
import { AuthService } from 'src/app/shared/services/auth.service'
import { UserdataService } from "../../../shared/services/userdata.service"
import { Icons } from 'src/app/shared/enums/icons'

@Component({
	selector: 'app-signin',
	templateUrl: './signin.component.html',
	styleUrls: ['./signin.component.scss'],
})

export class SigninComponent implements OnInit {

	icons = Icons;

	@Input() tabIndexValue: string = '0';

	constructor(
		public authService: AuthService,
		public userdataService: UserdataService,
	) {}

	ngOnInit() {
	}

}

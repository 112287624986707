import { Component, Input, OnInit, inject } from '@angular/core';
import { Title } from '@angular/platform-browser'; 
import { environment } from '../environments/environment';
import { Router, Event, NavigationStart, NavigationEnd, NavigationError} from '@angular/router';
import { HeaderService } from './shared/services/header.service';
import { trigger, style, animate, transition } from '@angular/animations';
import { ViewportScroller } from '@angular/common';
import { CMSDataService } from './shared/services/cmsdata.service';
import { LoggerService } from './shared/services/logger.service';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
	animations: [
		trigger('menuOverlayAnimation', [
			transition(':enter', [
				style({ opacity: 0 }),
				animate('.3s linear', 
				style({ opacity: 1 }))
			]),
			transition(':leave', [
				style({ opacity: 1 }),
				animate('.3s linear', 
				style({ opacity: 0 }))
			]),
		]),
		trigger('loaderFadeAnimation', [
			transition(':leave', [
				style({ opacity: 1 }),
				animate('1s linear', 
				style({ opacity: 0 }))
			]),
		]),
	],
})
			
export class AppComponent implements OnInit {

	logger: LoggerService = inject(LoggerService);

	showLoader = true;

	@Input() menuActive: boolean = false;

	constructor(
		private titleService: Title,
		private headerService: HeaderService,
		private router: Router,
		//private route: ActivatedRoute,
		private viewport: ViewportScroller,
		private cmsDataService: CMSDataService,
		//private meta: Meta,
	) {
		//this.currentRoute = '';
		this.router.events.subscribe((event: Event) => {
			if (event instanceof NavigationStart) {
				// Show progress spinner or progress bar
				headerService.menuClose();
				if (event.url === this.router.url) {
					this.logger.log("PREVENT RELOAD - CURRENT:", this.router.url, "TARGET:", event.url);
					return false;
				}
				return true;
			}
			
			if (event instanceof NavigationEnd) {
				// Hide progress spinner or progress bar
				
				//route = this.router.routerState.root;
				//while(route!.firstChild) route = route.firstChild;
				
				//headerService.setTopic(route!.snapshot.data['headerLabel']);
				headerService.setTopic('');
				this.viewport.scrollToPosition([0, 0]);
				
				
				//const bodyClass: string = route!.snapshot.data['bodyClass'];
				//document.body.className = bodyClass || '';
				
			}
			
			if (event instanceof NavigationError) {
				// Hide progress spinner or progress bar
				// Present error to user
				this.logger.error(event.error);
			}
			return true;
		});
		
	}
	
	ngOnInit(): void {
		this.titleService.setTitle(environment.appTitlePrefix + 'Congressional Medal of Honor Society | Path to Honor');
		this.headerService.setTopic(this.headerService.defaultHeaderTopic);
		this.headerService.menuActive().subscribe( value => { this.menuActive = value } );

		//this.meta.updateTag({ property: 'og:title', content: 'Congressional Medal of Honor Society | Path to Honor' });
		//this.meta.updateTag({ property: 'og:description', content: 'Real heroes set the stage in this history and values-based interactive program.'});
		//this.meta.updateTag({ property: 'og:url', content: location.origin});
		
		this.cmsDataService.dataLoaded.subscribe((loaded: boolean) => {
			if (loaded) {
				setTimeout(() => {
					this.showLoader = false;
				}, 1750);
			}
		});	
	}
	
	get title(): string {
		return this.titleService.getTitle();
	}

	menuClose(): void {
		this.headerService.menuClose();
	}
	
}

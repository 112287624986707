import { Injectable, inject } from '@angular/core';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { CuePointEvent } from '@vimeo/player';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Lesson } from '../interfaces/lesson';
import { Poll } from '../interfaces/poll';
import { CMSDataService } from './cmsdata.service';
import { HeaderService } from './header.service';
import { UserdataService } from './userdata.service';
import { LoggerService } from './logger.service';
import { LessonData } from '../interfaces/lessondata';

@Injectable({
	providedIn: 'root'
})
export class LessonService {
	logger: LoggerService = inject(LoggerService);

	public topicSlug: string = '';
	public lessonSlug: string = '';
	public currentTopic: string = '';
	public currentLesson?: Lesson;
	public currentPoll?: Poll;

	private _vimeoPlayerReady: BehaviorSubject<boolean>;
	private _pollActive: BehaviorSubject<boolean>;
	private _promptActive: BehaviorSubject<boolean>;
	private _certificationActive: BehaviorSubject<boolean>;

	private _videoPlayPauseToggle: boolean = false;
	private _videoPlayPauseToggleSource = new Subject<boolean>();
	videoPlayPauseToggle: Observable<boolean> = this._videoPlayPauseToggleSource.asObservable();

	private _videoControlsEnabled: boolean = false;
	private _videoControlsEnabledSource = new Subject<boolean>();
	videoControlsEnabled: Observable<boolean> = this._videoControlsEnabledSource.asObservable();

	public lessonInProgress: boolean = false;
	public lessonPlaying: boolean = false;
	public titleScreenDismissed: boolean = false;
	private completionWasLogged: boolean = false;

	constructor(
		private router: Router,
		private cmsDataService: CMSDataService,
		private userdataService: UserdataService,
		private headerService: HeaderService,
		private titleService: Title,
		private analytics: AngularFireAnalytics,
	) {
		this._vimeoPlayerReady = new BehaviorSubject<boolean>(false);
		this._pollActive = new BehaviorSubject<boolean>(false);
		this._promptActive = new BehaviorSubject<boolean>(false);
		this._certificationActive = new BehaviorSubject<boolean>(false);
	}

	public loadLessonFromRoute(topicSlug: string, lessonSlug: string): void {
		const isIntroduction: boolean = 'introduction' === topicSlug;

		if (isIntroduction) {
			this.currentTopic = 'Introduction';
			this.currentLesson = this.cmsDataService.getLesson('introduction', '');
			this.headerService.setTopic('');

		} else {

			if (topicSlug && lessonSlug) {
				this.topicSlug = topicSlug;
				this.lessonSlug = lessonSlug;
				this.currentTopic = this.cmsDataService.getTopic(topicSlug);
				this.currentLesson = this.cmsDataService.getLesson(topicSlug, lessonSlug);

				this.headerService.setTopic(this.currentTopic);
				this.titleService.setTitle(`${environment.appTitlePrefix}${this.currentTopic}: ${this.currentLesson?.title} | CMOHS Path to Honor`);
			}

			if (!this.currentLesson) {
				this.headerService.setTopic('');
				this.router.navigateByUrl('/lessons'); // lesson doesn't exist, return to courses
			}

		}

	}

	public resetLesson(): void {
		this.completionWasLogged = false;
		this.lessonPlaying = false;
		this.titleScreenDismissed = false;
		this.lessonInProgress = false;
		this.pauseVideo();
		this.closePoll();
		this.closePrompt();
		this.closeCertification();
		this.setVimeoPlayerReady(false);
	}

	public isVimeoPlayerReady(): Observable<boolean> { return this._vimeoPlayerReady.asObservable(); }
	public setVimeoPlayerReady(value: boolean): void {
		this._vimeoPlayerReady.next(value);
	}

	public isPollActive(): Observable<boolean> { return this._pollActive.asObservable(); }
	public openPoll(): void {
		this._pollActive.next(true);
		this.enableVideoControls(false);
	}
	public closePoll(): void {
		this._pollActive.next(false);
		this.playVideo();
		this.enableVideoControls(true);
	}

	public isPromptActive(): Observable<boolean> { return this._promptActive.asObservable(); }
	public openPrompt(): void {
		this._promptActive.next(true);
	}
	public closePrompt(): void {
		this._promptActive.next(false);
		this.openCertification();
	}

	public getPromptResponse(): string {
		const userLessonData = this.userdataService.userLessonData;
		if (this.currentLesson && userLessonData && userLessonData[this.currentLesson?.id]) {
			return userLessonData[this.currentLesson?.id].prompt;
		} else {
			return '';
		}
	}

	public logLessonAnalytics(action: string): void {
		if ('completed' === action) {
			if (this.completionWasLogged) {
				return;
			} else {
				this.completionWasLogged = true;
			}
		}

		if ( 'started' === action || 'viewed' === action ) {
			this.lessonInProgress = true;
		} else {
			this.lessonInProgress = false;
		}

		const params: object = {
			lesson_course: this.currentTopic,
			lesson_title: this.currentTopic + ': ' + (this.currentLesson?.title || ''),
			lesson_action: action,
		};
		this.logger.log('logLessonAnalytics', params);
		this.analytics.logEvent('lesson', params);
	}

	public completeLesson(promptResponse: string): void {
		if (this.currentLesson) {
			this.logger.log('*COMPLETE LESSON', this.currentLesson.id);
			this.currentLesson.completed = true;
			const lessonData: LessonData = {
				//id: this.currentLesson.id,
				prompt: promptResponse.replace(/\s+/g, ' ' ).trim()
			};
			this.userdataService.updateLessonProgress(this.currentLesson.id, lessonData);
		}
	}

	public editResponse(): void {
		this.closeCertification();
		this.openPrompt()
	}

	public isCertificationActive(): Observable<boolean> { return this._certificationActive.asObservable(); }
	public openCertification(): void {
		this._certificationActive.next(true);
	}
	public closeCertification(): void {
		this._certificationActive.next(false);
	}

	public closeLesson(): void {
		this.resetLesson();
		this.router.navigate(['/lessons', this.topicSlug]);
	}

	public enableVideoControls(enable: boolean): void {
		this._videoControlsEnabled = enable;
		this._videoControlsEnabledSource.next(this._videoControlsEnabled);
	}

	public isVideoPlaying(): Observable<boolean> { return this.videoPlayPauseToggle; }

	public playVideo(): void {
		this._videoPlayPauseToggle = true;
		this._videoPlayPauseToggleSource.next(this._videoPlayPauseToggle);
	}
	public pauseVideo(): void {
		this._videoPlayPauseToggle = false;
		this._videoPlayPauseToggleSource.next(this._videoPlayPauseToggle);
	}

	public clickPlayPause():void {
		this.logger.log('SERVICE clickPlayPause');
		this._videoPlayPauseToggle = !this._videoPlayPauseToggle;
		this._videoPlayPauseToggleSource.next(this._videoPlayPauseToggle);
	}

	public onCuepoint(event: CuePointEvent): void {
		this.logger.log('Video cuepoint', event);

		if (event.data['poll']) {

			let pollKey: string = event.data['poll'];
			this.logger.log('POLL KEY', pollKey);

			this.currentPoll = this.currentLesson?.polls?.find(obj => obj.id === pollKey);
			if (this.currentPoll) {
				this.pauseVideo();
				this.openPoll();
			}

		}

	}


}

import { Injectable } from '@angular/core';
import { LogLevel } from '../enums/loglevel';
import { environment } from 'src/environments/environment';

@Injectable({
	providedIn: 'root'
})
export class LoggerService {
	level: LogLevel;
	
	constructor() {
		this.level = environment.production ? LogLevel.Error : LogLevel.All;
	}
	
	get debug(): Function { return (this.shouldLog(LogLevel.Debug) ? console.log.bind(window.console) : () => {}) ; }
	//debug(...params: any[]) { this.outputLog(LogLevel.Debug, params); }

	get info(): Function { return (this.shouldLog(LogLevel.Info) ? console.log.bind(window.console) : () => {}) ; }
	//info(...params: any[]) { this.outputLog(LogLevel.Info, params); }

	get warn(): Function { return (this.shouldLog(LogLevel.Warn) ? console.log.bind(window.console) : () => {}) ; }
	//warn(...params: any[]) { this.outputLog(LogLevel.Warn, params); }

	get error(): Function { return (this.shouldLog(LogLevel.Error) ? console.log.bind(window.console) : () => {}) ; }
	//error(...params: any[]) { this.outputLog(LogLevel.Error, params); }

	get log(): Function { return (this.shouldLog(LogLevel.All) ? console.log.bind(window.console) : () => {}) ; }
	//log(...params: any[]) { this.outputLog(LogLevel.All, params); }

	/*
	private outputLog(level: LogLevel, params: any[]) {
		if (this.shouldLog(level)) {
			switch (level) {
				case LogLevel.Debug:
					console.debug.apply(console, params);
				break;
				case LogLevel.Info:
					console.info.apply(console, params);
				break;
				case LogLevel.Warn:
					console.warn.apply(console, params);
				break;
				case LogLevel.Error:
					console.error.apply(console, params);
				break;
				default:
					console.log.apply(console, params);
			}
		}
	}
	*/
	
	private shouldLog(level: LogLevel): boolean {
		return ((level >= this.level && level !== LogLevel.Off) || this.level === LogLevel.All);
	}
}

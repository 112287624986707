export enum Icons {
    ARROW = 'arrow',
    CLOSE = 'close',
    DOWNLOAD = 'download',
    GOOGLE = 'google',
    PRINT = 'print',
    CC_ENABLE = 'cc-enable',
    CC_DISABLE = 'cc-disable',
    PAUSE = 'pause',
    PLAY = 'play',
    LIST = 'list',
    EDIT = 'edit',
    EXTERNAL = 'external',
    LOADING = 'loading',
}

<div class="inline-flex flex-col space-y-10">
	<app-button
	[tabIndexValue]="tabIndexValue"
	(onClick)="authService.GoogleAuth()"
	[label]="'Sign in with Google'"
	[icon]="icons.GOOGLE"
	[command]="'authenticate'"
	class="large rounded icon-left googleauth">
</app-button>
<a tabindex="{{ tabIndexValue }}" (click)="userdataService.continueToApp()" routerLink="/introduction" class="underline underline-offset-4 hover:no-underline">Continue as Guest</a>
</div>

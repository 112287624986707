<section class="max-w-4xl px-6 md:px-0">
	<h2>Certificate of Completion</h2>
	<p>Enter your name to generate your Certificate of Completion.</p>
</section>
<div class="w-full md:w-auto px-6 md:px-0">
	<div class="mb-6 text-2xl">
		<input 
		tabindex="{{ tabIndexValue }}"
		class="text-center" placeholder="Your Name" [(ngModel)]="name" (ngModelChange)="onModelChange($event)" />
	</div>

	<app-get-certificate tabIndexValue="{{ tabIndexValue }}" [icon]="icons.DOWNLOAD" [name]="name" [lessonTopic]="lessonTopic" [lessonTitle]="lessonTitle" [lessonSubtitle]="lessonSubtitle" [prompt]="prompt" [answer]="answer" [disabled]="certificateDisabled"></app-get-certificate>

	<div class="flex flex-col md:flex-row justify-center space-y-6 md:space-y-0 md:space-x-6 mt-6">
		<app-button tabIndexValue="{{ tabIndexValue }}" class="shy smwide" [icon]="icons.EDIT" label="Edit Response" [command]="'editcertificate'" (click)="editResponse($event)"></app-button>
		<app-button tabIndexValue="{{ tabIndexValue }}" class="shy smwide" [icon]="icons.LIST" label="View {{ lessonService.currentTopic }} Lessons" [command]="'continue'" (click)="closeCertification($event)"></app-button>
	</div>
</div>


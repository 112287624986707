<div class="flex flex-grow flex-col" tabindex="-1" [routerLink]="generateTopicPath()">

<div class="mb-5 relative">
	<div class="aspect-[2.35/1]"><img [src]="course.photoURL | getDownloadURL" alt="" class="w-full rounded-3xl object-cover"></div>
</div>
<header class="flex flex-row items-center pb-3">
	<h3 class="flex-grow text-6xl mb-0 uppercase text-blue-dark">{{course.topic}}</h3>
	<div class="pl-5 min-w-[10.75rem]">
		<app-progress-bar [lessonsTotal]="lessonsTotal" [lessonsCompleted]="lessonsCompleted" [courseProgress]="courseProgress"></app-progress-bar>
	</div>
</header>

<p class="text-2xl lg:text-xl leading-8 lg:leading-6 mb-5 ">{{course.description}}</p>

<div class="flex-grow flex">
	<div class="self-end w-full">
		<!--<app-button [element]="'anchor'" class="small" 
		[label]="getButtonLabel()" [icon]="getButtonIcon()"
		[navigateTo]="lessonsTotal > 1 ? generateTopicPath() : generateLessonPath(course.lessons[0])"></app-button> -->
		<app-button tabIndexValue="{{ menuActive ? '-1' : '0' }}" [element]="'anchor'" class="small smwide" 
		label="View {{ course.topic }} Lessons" [icon]="icons.LIST"
		[navigateTo]="generateTopicPath()"></app-button> 
		
	</div>
</div>

</div>


import { Component, Input, OnInit, inject } from '@angular/core';
import { gsap } from 'gsap';
import { Linear, Power2, Power4 } from 'gsap';
import { HeaderService } from 'src/app/shared/services/header.service';
import { AuthService } from '../../../shared/services/auth.service';
import { LoggerService } from 'src/app/shared/services/logger.service';
import { LessonService } from 'src/app/shared/services/lesson.service';

@Component({
	selector: 'app-site-header',
	templateUrl: './site-header.component.html',
	styleUrls: ['./site-header.component.scss']
})
export class SiteHeaderComponent implements OnInit {
	logger: LoggerService = inject(LoggerService);

	@Input() topicText: string = this.headerService.defaultHeaderTopic;
	@Input() menuActive: boolean = false;
	
	tlBurger!: gsap.core.Timeline;
	tlTopic!: gsap.core.Timeline;
	
	constructor(
		public headerService:HeaderService, 
		public authService: AuthService, 
		public lessonService: LessonService
	) {}
	
	ngOnInit(): void {
		this.tlBurger = gsap.timeline({paused: true, reversed: true});
		this.tlBurger
			.to("#menutoggle path", {duration: 0.3, stroke: '#000000', ease:Linear.easeNone}, 'start')
			.to("#menutoggle .upper", {duration: 0.3, attr: {d: "M8,2 L2,8"}, x: 1, ease:Power2.easeInOut}, 'start')
			.to("#menutoggle .middle", {duration: 0.3, autoAlpha: 0, x: "-50%"}, 'start')
			.to("#menutoggle .lower", {duration: 0.3, attr: {d: "M8,8 L2,2"}, x: 1, ease:Power2.easeInOut}, 'start')
		;
		
 		this.tlTopic = gsap.timeline({paused: true});
		this.tlTopic
			.from("#topictext", {duration: 2, x: 30, ease:Power4.easeOut}, 'start')
			.from("#topictext", {duration: 2, opacity: 0, ease:Linear.easeNone}, 'start')
		;
		 
	}
	
	ngAfterViewInit(): void {
		this.headerService.getTopic().subscribe( value => this.topicChange(value) );
		this.headerService.menuActive().subscribe( value => this.setBurgerState(value) );
	}

	topicChange(value: string): void {
		const changed: boolean = this.topicText !== value;
		this.logger.log('topic changed', changed, value);
		this.topicText = value;
		if (changed) {
			this.tlTopic.restart();
		} else {
			this.tlTopic.seek('-=0');
		}
	}
	
	menuToggle(event: MouseEvent): void {
		this.headerService.menuToggle();	
	}

	setBurgerState(value: boolean): void {
		this.menuActive = value;
		value ? this.tlBurger.play() : this.tlBurger.reverse();
	}
		
	
}

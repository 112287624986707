import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanDeactivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { LessonComponent } from 'src/app/components/lesson/lesson.component';

export interface ComponentCanDeactivate {
	canDeactivate: () => boolean | Observable<boolean>;
}

@Injectable({
	providedIn: 'root'
})
export class LessonNavGuard implements CanDeactivate<unknown> {
	canDeactivate(
		component: LessonComponent,
		currentRoute: ActivatedRouteSnapshot,
		currentState: RouterStateSnapshot,
		nextState?: RouterStateSnapshot
	): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree
	{
		return new Observable<boolean | UrlTree>((observable) => {
			return component.canDeactivate() ? observable.next(true) : observable.next(
				confirm(
					'Warning: If you navigate away, your progress for this Lesson will not be saved. Press "Cancel" to return to the Lesson, or "OK" to confirm navigation.'
				)
			);
		});
	}
}

import { Component, EventEmitter, OnInit, Input, Output, inject } from '@angular/core';
import { Icons } from 'src/app/shared/enums/icons';
import { LessonService } from 'src/app/shared/services/lesson.service';
import { AuthService } from '../../../shared/services/auth.service';
import { LoggerService } from 'src/app/shared/services/logger.service';

@Component({
	selector: 'app-prompt',
	templateUrl: './prompt.component.html',
	styleUrls: [
		'../certification/certification.component.scss',
		'./prompt.component.scss'
	]
})
export class PromptComponent implements OnInit {
	logger: LoggerService = inject(LoggerService);

	@Input() tabIndexValue: string = '0';

	@Input() char: number = 0;
	maxChar = 400;
	counter = true;

	prompt?: string = this.lessonService.currentLesson ? this.lessonService.currentLesson.finalQuestion : '';
	answer: string = '';

	@Output() onClick = new EventEmitter();

	userClosedPrompt: boolean = false;
	continueDisabled: boolean = true;

	icons = Icons;

	constructor(public lessonService: LessonService, public authService: AuthService) {
		this.answer = this.lessonService.getPromptResponse();
		this.char = this.answer ? this.answer.trim().length : 0;
		this.continueDisabled = this.answer?.trim().length > 0 ? false : true;
	}

	ngOnInit(): void {
	}

	closePrompt(event: Object): void {
		this.logger.log('Close Prompt', event);
		this.userClosedPrompt = true;
		this.lessonService.logLessonAnalytics('completed');
		this.lessonService.closePrompt();
		this.lessonService.lessonPlaying = false;
		this.lessonService.completeLesson(this.answer);
	}

	onModelChange(textValue: string): void {
		this.char = textValue.trim().length;
		this.continueDisabled = this.char > 0 ? false : true;
	}

}

import { Component, ElementRef, HostListener, Input, OnInit, ViewChild, inject } from '@angular/core';
import { Icons } from 'src/app/shared/enums/icons';
import Player, { CuePointEvent, Error, Options, TimeEvent, VimeoTextTrack } from '@vimeo/player';
import { CuePoint } from '../../../shared/interfaces/cuepoint';
import { MinuteSecondsPipe } from 'src/app/shared/pipes/minuteseconds.pipe';
import { ActivatedRoute, Router } from '@angular/router';
import { LessonService } from 'src/app/shared/services/lesson.service';
import { Subscription } from 'rxjs';
import { UserdataService } from "../../../shared/services/userdata.service";
import { HeaderService } from 'src/app/shared/services/header.service';
import { LoggerService } from 'src/app/shared/services/logger.service';

@Component({
	selector: 'app-video-player',
	templateUrl: './video-player.component.html',
	styleUrls: ['./video-player.component.scss'],
	providers: [
		MinuteSecondsPipe,
	],
})
export class VideoPlayerComponent implements OnInit {
	logger: LoggerService = inject(LoggerService);

	@Input() playerError?: string;
	@Input() videoProgress: number = 0;
	@Input() videoTime: number = 0;
	@Input() videoDuration: number = 0;
	@Input() hasTextTrack: boolean = true; // language: 'en-US'
	@Input() ccEnabled: boolean = false;
	@Input() isPlaying: boolean = false;
	@Input() tabIndexValue: string = '0';

	subscriptions: Subscription[] = [];

	private startedPlaying: boolean = false; // flag for starting the entire video
	private viewedAll: boolean = false; // flag for viewing the entire video

	@HostListener('document:keydown', ['$event']) onKeyDown(event: any) {
		if (this.controlsEnabled) {

			if(event.key === 'MediaStop') {
				this.logger.log('keydown MediaStop');

				this.mediaStopPressed = true;
			}
			
			if(event.key === 'MediaPlayPause') {
				this.logger.log('keydown MediaPlayPause');

				if (this.mediaStopPressed) {
					const video = this;
					this.player.getPaused().then(
						() => {
							this.player.play().then(
								() => {
									this.lessonService.playVideo();
									this.logger.log('play after MediaStop', event);
									this.mediaStopPressed = false;
								},
								(error: Error) => video.onPlayerError(error.message)
							);
						},
						(error: Error) => video.onPlayerError(error.message)
					);
				}
			}

		}
	}

	private mediaStopPressed: boolean = false;

	private _controlsEnabled: boolean = false;
	@Input('controlsEnabled')
	set controlsEnabled(value: boolean) {
		this._controlsEnabled = value;
		this.logger.log('controlsEnabled', value);
	}
	get controlsEnabled(): boolean {
		return this._controlsEnabled;
	}

	@ViewChild('player_container') playerContainer!: ElementRef;
	@ViewChild('player_error_container') playerErrorContainer!: ElementRef;
	@ViewChild('player_error_message') playerErrorMessage!: ElementRef;
	@ViewChild('cuepoint_indicators') cuePointIndicators!: ElementRef;

	icons = Icons;

	private player!:Player;
	private playerOptions:Options = {
		id: 0,
		//id: 752955032,
		byline: false,
		color: '406cb4',
		controls: false,
		keyboard: false,
		portrait: false,
		responsive: true,
		title: false,
		width: 1920,
	};

	constructor(
		private router: Router,
		private lessonService: LessonService,
		private userdataService: UserdataService,
		public headerService: HeaderService,
	) {}

	onEnded(event: TimeEvent): void {
		this.logger.log('Video ended', event);
		this.viewedAll = true;
		this.mediaStopPressed = false;
		this.lessonService.pauseVideo();
		this.lessonService.enableVideoControls(false);
		this.videoTime = this.videoDuration;

		if (this.lessonService.currentLesson?.isIntroduction) {
			this.userdataService.introComplete();
			this.lessonService.logLessonAnalytics('viewed');
			this.lessonService.logLessonAnalytics('completed');
		} else {
			this.lessonService.logLessonAnalytics('viewed');
		}

		if (this.lessonService.currentLesson?.endRoute) {
			this.router.navigateByUrl(this.lessonService.currentLesson.endRoute);
		} else {
			this.lessonService.openPrompt();
		}
	}

	onTimeUpdate(event: TimeEvent): void {
		//this.logger.log('Video timeupdate', event);
		this.videoProgress = event.percent * 100;
		this.videoTime = Math.floor(event.seconds);
	}

	onPlayerError(message: string): void {
		this.logger.error(message);
		this.player.pause();
		this.controlsEnabled = false;
		this.playerErrorMessage.nativeElement.innerText = message;
		this.playerErrorContainer.nativeElement.setAttribute('style', 'visibility: visible');
		this.playerContainer.nativeElement.setAttribute('style', 'visibility: hidden');
	}

	ngOnInit(): void {
		this.playerOptions.id = Number(this.lessonService.currentLesson?.vimeoID) || 0;
		this.logger.log("VIMEOID: ", this.lessonService.currentLesson?.vimeoID);

		this.subscriptions.push(
			this.lessonService.videoControlsEnabled.subscribe(value => this.controlsEnabled = value),
			//this.headerService.menuActive().subscribe( value => this.menuActive = value ),
		);
	}

	ngAfterViewInit(): void {
		this.player = new Player(this.playerContainer.nativeElement, this.playerOptions);

		let playerSetupPromises: any[] = [];

		playerSetupPromises.push(this.player.ready());
		playerSetupPromises.push(this.player.getDuration().then(
			(value: number) => { this.videoDuration = Math.floor(value); }
		));
		//this.cuePoints.forEach((element: CuePoint) => {
		this.lessonService.currentLesson?.cuePoints?.forEach((element: CuePoint) => {
			playerSetupPromises.push(
				this.player.addCuePoint(element.time, {[element.key]: element.value}).then(
					(value: string) => {
						if (element.key === 'poll') {
							this.drawCuePoint(element.time);
						}
					}
				)
			);
		});

		Promise.all(playerSetupPromises).then((values) => {
			//this.logger.log(values);

			this.controlsEnabled = true;

			this.playerContainer.nativeElement.querySelector('iframe').setAttribute('tabindex', '-1');

			this.player.getTextTracks().then(
				(textTracks: VimeoTextTrack[]) => {
					this.logger.log(textTracks);
					if(textTracks[0].language === 'en-US' && textTracks[0].kind === 'subtitles') {
						this.hasTextTrack = true;
						this.ccEnabled = textTracks[0].mode === 'showing';
					} else {
						this.hasTextTrack = false;
					}
				}
			);

			this.lessonService.setVimeoPlayerReady(true);
			// Autoplay
			//this.lessonService.playVideo();

		}).catch((error: Error) => {
			this.onPlayerError(error.message);
			this.lessonService.titleScreenDismissed = true;
		});

		this.player.on('ended', (event: TimeEvent) => this.onEnded(event));
		this.player.on('timeupdate', (event:TimeEvent) => this.onTimeUpdate(event));
		//this.player.on('cuepoint', (event: CuePointEvent) => this.onCuepoint(event));
		this.player.on('cuepoint', (event: CuePointEvent) => this.lessonService.onCuepoint(event));
		//this.player.on('playing', (event: TimeEvent) => this.logger.log('playing', event));
		this.player.on('play', (event: TimeEvent) => {
			if (this.controlsEnabled) {
				this.lessonService.playVideo();
				this.logger.log('play', event);
			} else {
				this.player.pause().then(
					() => null,
					(error: Error) => video.onPlayerError(error.message)
				);
			}
		});
		this.player.on('pause', (event: TimeEvent) => {
			this.lessonService.pauseVideo();
			this.logger.log('pause', event);
		});

		const video = this;
		this.subscriptions.push(
			this.lessonService.isVideoPlaying().subscribe((value: boolean) => {
				this.logger.log('isVideoPlaying', value);
				this.isPlaying = value;


				if (value) {
					this.player.play().then(
						() => this.startedPlaying = true,
						(error: Error) => video.onPlayerError(error.message)
					);
				} else {
					this.player.pause().then(
						() => null,
						(error: Error) => video.onPlayerError(error.message)
					);
				}


			})
		);
	}

	ngOnDestroy(): void {
		//this.logger.log('videoPlayer ngOnDestroy, viewedAll:', this.viewedAll);
		if (this.startedPlaying && !this.viewedAll) this.lessonService.logLessonAnalytics('abandoned');
		this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
	}

	drawCuePoint(seconds:number): void {
		this.logger.log(`${seconds} / ${this.videoDuration}`);
		const pct: number = seconds / this.videoDuration * 100;
		const marker: HTMLDivElement = document.createElement('div');
		marker.className = 'cue-marker absolute w-1.5 h-2 bg-red-light top-0 -translate-x-1/2';
		marker.style.left = `${pct}%`;
		this.cuePointIndicators.nativeElement.appendChild(marker);
	}

	clickPlayPause(event: MouseEvent):void {
		this.mediaStopPressed = false;
		this.lessonService.clickPlayPause();
	}

	toggleCC(event: MouseEvent):void {
		const video = this;
		if (this.ccEnabled) {
			this.player.disableTextTrack().then(
				() => this.ccEnabled = false,
				(error: Error) => video.onPlayerError(error.message)
			);
		} else {
			this.player.enableTextTrack('en-US', 'subtitles').then(
				(textTrack: VimeoTextTrack) => { this.ccEnabled = true; this.logger.log(textTrack); },
				(error: Error) => video.onPlayerError(error.message)
			);
		}
	}

}

<section class="max-w-4xl">
	<h2>What Would You Do?</h2>
	<p>{{ lessonService.currentPoll?.description }}</p>
	<h3 id="pollheadingtext">{{ pollHeading }}</h3>
</section>
<section class="pollChoices mb-12">
	<app-choice
		*ngFor="let pollChoice of lessonService.currentPoll?.choices; let i = index" 
		[selected]="i === userChoiceIndex" 
		[disabled]="userChoiceLocked" 
		[choice]="pollChoice" [choiceIndex]="i" 
		[percentage]="resultsDisplayPct[i]" 
		[tabIndexValue]="tabIndexValue" 
		(click)="onMadeChoice(i)"></app-choice>
</section>
<app-button [tabIndexValue]="tabIndexValue" *ngIf="!userChoiceLocked" [icon]="icons.ARROW" ariaLabelOverride="Lock in my choice and see results" label="See Results" [disabled]="userChoiceIndex === null" [command]="'showResults'" (click)="showResults($event)"></app-button>
<app-button [tabIndexValue]="tabIndexValue" *ngIf="userChoiceLocked" [ngClass]="resultsPending ? 'loading' : ''" [icon]="resultsPending ? icons.LOADING : icons.ARROW" ariaLabelOverride="Continue with the lesson" label="Continue" [disabled]="resultsPending || userClosedPoll" [command]="'continue'" (click)="closePoll($event)"></app-button>


<div class="text-gray-dark font-bold">
	<span [ngPlural]="lessonsCompleted">
		<ng-template ngPluralCase="=0">
			{{ lessonsTotal }}
			<span [ngPlural]="lessonsTotal">
				<ng-template ngPluralCase="=1">Lesson</ng-template>
				<ng-template ngPluralCase="other">Lessons</ng-template>
			</span>
			Available
		</ng-template>
		<ng-template ngPluralCase="other">
			<span [ngPlural]="(lessonsTotal - lessonsCompleted)">
				<ng-template ngPluralCase="=0">All Lessons</ng-template>
				<ng-template ngPluralCase="other">
					{{ lessonsCompleted }} of {{ lessonsTotal }}
					<span [ngPlural]="lessonsTotal">
						<ng-template ngPluralCase="=1">Lesson</ng-template>
						<ng-template ngPluralCase="other">Lessons</ng-template>
					</span>
				</ng-template>
			</span>
			Complete
		</ng-template>
	</span>

	<!--<span [ngPlural]="lessonsRemaining">
		<ng-template ngPluralCase="=0">
			{{ lessonsTotal }}
			<span [ngPlural]="lessonsTotal">
				<ng-template ngPluralCase="=1">Lesson</ng-template>
				<ng-template ngPluralCase="other">Lessons</ng-template>
			</span>
			Completed
		</ng-template>
		<ng-template ngPluralCase="other">
			{{ lessonsRemaining }}
			<span [ngPlural]="lessonsRemaining">
				<ng-template ngPluralCase="=1">Lesson</ng-template>
				<ng-template ngPluralCase="other">Lessons</ng-template>
			</span>
			to Complete
		</ng-template>
	</span>-->
</div>
<div id="course-progress" class="flex flex-1 items-center">
	<div class="flex-1 h-1 bg-gold-dark/40 rounded-lg relative">
		<div class="h-1 bg-gold-dark rounded-lg w-0" [attr.style]="'width:' + courseProgress + '%'"></div>
		<div #bar_dividers class="h-1 bg-transparent w-full absolute top-0 left-0"></div>
	</div>
</div>


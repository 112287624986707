import { Component, OnInit } from '@angular/core';
import { Icons } from 'src/app/shared/enums/icons';
import { PageComponent } from 'src/app/components/pages/page/page.component';
import { CMSDataService } from 'src/app/shared/services/cmsdata.service';
import { DomSanitizer, SafeResourceUrl, Title } from '@angular/platform-browser';
import { TitleCasePipe } from '@angular/common';
import { environment } from 'src/environments/environment';

@Component({
	selector: 'app-about',
	templateUrl: './about.component.html',
	styleUrls: ['../page/page.component.scss', './about.component.scss'],
	providers: [TitleCasePipe],
})
export class AboutComponent extends PageComponent implements OnInit {
	
	icons = Icons;

	vimeoEmbedURL: SafeResourceUrl;
	
	constructor(
		private titleService: Title,
		public titleCasePipe: TitleCasePipe,
		public cmsDataService: CMSDataService,
		public sanitizer: DomSanitizer,
	) {
		super();
		if (cmsDataService.configuration.globalStrings.aboutVimeoID) {
			this.vimeoEmbedURL = this.sanitizer.bypassSecurityTrustResourceUrl( 'https://player.vimeo.com/video/' + cmsDataService.configuration.globalStrings.aboutVimeoID );
		} else {
			this.vimeoEmbedURL = '';
		}
	}
	
	override ngOnInit(): void {
		super.ngOnInit();
		let tcTitle: string = this.titleCasePipe.transform(this.cmsDataService.configuration.globalStrings.aboutTitle);
		this.titleService.setTitle(`${environment.appTitlePrefix}${tcTitle} | CMOHS Path to Honor`);
	}

	override ngOnDestroy(): void {
		super.ngOnDestroy();
	}
	
}

<section>
	<h1>{{ cmsDataService.configuration.globalStrings.aboutTitle | titlecase }}</h1>
	<p [innerHTML]="cmsDataService.configuration.globalStrings.aboutText | filterKeyPhrases"></p>

	<div *ngIf="cmsDataService.configuration.globalStrings.aboutVimeoID" class="my-16" style="padding:56.25% 0 0 0;position:relative;"><iframe tabindex="{{ menuActive ? '-1' : '0' }}" [src]="vimeoEmbedURL" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style="position:absolute;top:0;left:0;width:100%;height:100%;"></iframe></div><script src="https://player.vimeo.com/api/player.js"></script>
	
</section>
<section>
	<h2>Resources</h2>
	<nav>
		<ul>
			<li><a tabindex="{{ menuActive ? '-1' : '0' }}" href="https://www.cmohs.org/" target="_blank" rel="external">Congressional Medal of Honor Society &ndash; Official Website</a></li>
			<li><a tabindex="{{ menuActive ? '-1' : '0' }}" href="https://www.cmohs.org/news-events/privacy-policy/" target="_blank" rel="external">Privacy Policy</a></li>
		</ul>
	</nav>
	<p class="opacity-70 text-xl italic mt-12"><img class="h-4 w-4 mr-1 inline-block" src="/assets/icons/{{ icons.EXTERNAL }}.svg"> links will open in a new tab</p>
</section>

<section *ngIf="cmsDataService.configuration.credits?.section?.length" class="credits mt-24 !text-gray-medium">
	<h2 class="!text-6xl md:!text-8xl">{{ (cmsDataService.configuration.credits?.heading || 'Credits') | titlecase }}</h2>

	<div *ngIf="cmsDataService.configuration.sponsorship" class="sponsor">
		<h3 class="font-body font-bold !normal-case !text-current !text-2xl md:!text-4xl !mb-6 md:!mb-8">{{ cmsDataService.configuration.sponsorship.attribution }}</h3>
		<div class="pb-12 mb-12 border-b border-b-black/20 inline-block">
			<img class="w-full max-w-sm md:max-w-md lg:max-w-lg py-3 lg:py-6" alt="" [src]="cmsDataService.configuration.sponsorship.logoURL['default'] | getDownloadURL">
		</div>
	</div>

	<div *ngFor="let creditsection of cmsDataService.configuration.credits?.section">
		<h3 class="font-body font-bold !normal-case !text-current !text-2xl md:!text-4xl !mb-6 md:!mb-8">{{ creditsection.heading }}</h3>
		<ul class="mb-16 md:mb-24 text-2xl md:text-3xl">
			<li class="mb-6 md:mb-4 leading-tight" *ngFor="let item of creditsection.item">{{ item }}</li>
		</ul>
	</div>
</section>
